import { CartReviewContext } from '@components/camp/reservations/Cart/Review'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col, Card } from 'react-bootstrap'
import classNames from 'classnames'
import { formatDollars, formatCurrency } from '@utils/strings'
import BookingCartItem from './BookingCartItem'

const CartSummary: React.FC = observer(() => {
  const store = useContext(CartReviewContext)
  const previousCart = store.history ? store.history.previous_cart : null

  return (
    <Card style={{ marginTop: '-140px' }} className="shadow-sm">
      <Card.Body>
        <Row>
          <Col className="text-center">
            <p className="h5 mb-0">Reservation Details</p>
            <p className="overline text-uppercase text-gray-500">{store.reservation.park_name}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {store.aggregatedBookings.map((booking, idx) => (
              <BookingCartItem
                booking={booking}
                firstBooking={idx == 0}
                key={`booking-${booking.id}`}
              />
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="m-3 px-0 py-3 border-top">
            <div className="d-flex justify-content-between">
              <p className="mb-0">Site Total</p>
              <p
                className={classNames('mb-0', {
                  'text-red-600': previousCart && previousCart.booking_subtotal != store.siteTotal,
                })}>
                ${store.siteTotal}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Add-Ons Total</p>
              <p
                className={classNames('mb-0', {
                  'text-red-600':
                    previousCart && previousCart.consumable_subtotal != store.consumableTotal,
                })}>
                ${store.consumableTotal}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Campground Fees</p>
              <p className="mb-0">${store.campgroundFees}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Taxes</p>
              <p
                className={classNames('mb-0', {
                  'text-red-600': previousCart && previousCart.tax != store.taxes,
                })}>
                ${store.taxes}
              </p>
            </div>
            {/* customer owes more after edited */}
            {store.reservation.is_draft && store.editedReservationTotal >= 0 ? (
              <>
                <div className="d-flex justify-content-between mt-2">
                  <p className="h5 mb-0">Amount Paid</p>
                  <p className="h5 mb-0">${store.reservation.previous_payment_amount}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="h5 mb-0">Amount Due</p>
                  <p
                    className={classNames('h5 mb-0', {
                      'text-red-600':
                        previousCart && previousCart.grand_total != store.reservationTotal,
                    })}>
                    {formatDollars(store.editedReservationTotal)}
                  </p>
                </div>
              </>
            ) : null}
            {/* customer recieves refund after edits */}
            {store.reservation.is_draft && store.editedReservationTotal < 0 ? (
              <div className="d-flex justify-content-between mt-2">
                <p className="h5 mb-0 text-red-600">Refund Amount</p>
                <p className="h5 mb-0 text-red-600">
                  ${formatCurrency(Math.abs(store.editedReservationTotal))}
                </p>
              </div>
            ) : null}
            {/* new reservation */}
            {!store.reservation.is_draft ? (
              <div className="d-flex justify-content-between">
                <p className="h5 mb-0">Reservation Total</p>
                <p className="h5 mb-0">${store.reservationTotal}</p>
              </div>
            ) : null}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
})

export default CartSummary
