// For when you just need an effect to run once on mount with 0 dependencies

import { useEffect } from 'react'

const useDidMount = (f) => {
  useEffect(() => {
    // Cleanup effects when f returns a function
    return f && f()
  }, [])
}

export default useDidMount
