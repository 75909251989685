import qs from 'qs'
import useFetch from 'use-http'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Popover } from 'react-bootstrap'
import { useSearchQuery } from '@hooks/useSearchQuery'
import {
  formatISO,
  parseISO,
  addDays,
  subDays,
  startOfDay,
  differenceInCalendarDays,
} from 'date-fns'
import Flatpickr from 'react-flatpickr'
import { calculateSiteSubtotal } from '@components/camp/shared/calculateSiteSubtotal'
import { countDisplay, formatCurrency } from '@utils/strings'

interface BookSiteProps {
  reservation: any
  site: any
  es_site: any
  park_seasons: any
}

const BookSite: React.FC<BookSiteProps> = ({ reservation, site, es_site, park_seasons }) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const booking = reservation.bookings ? reservation.bookings.find((booking) => !booking.id) : null

  const [bookingAttrs, setBookingAttrsState] = useState({
    check_in: booking ? booking.start_at : params.check_in || '',
    check_out: booking ? booking.end_at : params.check_out || '',
    occupants: booking ? booking.occupant_count : params.occupants || '',
    vehicles: booking ? booking.vehicle_count : params.vehicles || '',
  })

  const paramsToString = (obj) => {
    const queryString = qs.stringify(obj, { arrayFormat: 'brackets' })
    return queryString.length > 0 ? `?${queryString}` : ''
  }

  const setBookingAttrs = (attrs) => {
    setBookingAttrsState(attrs)
    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
    history.replaceState({}, document.title, `${baseUrl}${paramsToString(attrs)}`)

    // FIXME: This is a hack to store the user's location prior to signing in.
    // `ApplicationController#store_user_location!` only works when a GET request
    // has been fired, and updating the URL above does not carry over when user is
    // prompted to sign in.
    // So, we just fetch the url here and do nothing with the response.
    window.fetch(`${baseUrl}${paramsToString(attrs)}`)
  }

  const disableDates = es_site.active_booking_dates_array.map((str) => parseISO(str))
  const checkInDate: Date = bookingAttrs.check_in && parseISO(bookingAttrs.check_in)
  const checkOutDate: Date = bookingAttrs.check_out && parseISO(bookingAttrs.check_out)
  const baseName = 'camp_reservation[bookings_attributes][0]'

  const displayPrice = bookingAttrs.check_in && bookingAttrs.check_out

  const setDate = (type, value) => {
    const dateString = formatISO(value, { representation: 'date' }) // format(value, 'yyyy-MM-dd')
    setBookingAttrs({ ...bookingAttrs, [type]: dateString })
  }

  const onCheckInChange = (dates) => {
    const date = dates[0]
    if (date >= checkOutDate) setDate('check_out', addDays(date, 1))
    setDate('check_in', date)
  }

  const onCheckOutChange = (dates) => {
    const date = dates[0]
    if (date <= checkInDate) setDate('check_in', subDays(date, 1))
    setDate('check_out', date)
  }

  const pricingSummary = () => {
    const nights = differenceInCalendarDays(checkOutDate, checkInDate) || 0
    const subTotal = parseFloat(calculateSiteSubtotal(
      es_site,
      checkInDate,
      checkOutDate,
      park_seasons
    ) as string)

    const reservationFee = parseFloat(site.reservation_fee) || 0
    const taxes = (subTotal + reservationFee) * (parseFloat(site.tax_multiplier) || 0)
    const total = subTotal + reservationFee + taxes

    return (
      <Row>
        <Col className="m-3 px-0 py-3 border-top border-bottom">
          <div className="d-flex justify-content-between">
            <p className={'mb-0'}>{countDisplay('Night', nights)}</p>
            <p className={'mb-0'}>${formatCurrency(subTotal)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className={'mb-0'}>Campground Fees</p>
            <p className={'mb-0'}>${formatCurrency(reservationFee)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className={'mb-0'}>Taxes</p>
            <p className={'mb-0'}>${formatCurrency(taxes)}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className={'h5 mb-0'}>Site Total</p>
            <p className={'h5 mb-0'}>${formatCurrency(total)}</p>
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <React.Fragment>
      <input type="hidden" name={`${baseName}[zone_id]`} value={site.id} />
      <Row>
        <Col xs={6}>
          <div className="form-group">
            <label className="form-label font-size-sm text-gray-500">
              Check-In
              <div className="input-group mt-1">
                <Flatpickr
                  className="form-control bg-white"
                  options={{
                    dateFormat: 'm/d/Y',
                    minDate: startOfDay(new Date()),
                    disable: disableDates,
                  }}
                  value={checkInDate}
                  placeholder="Check-In"
                  onChange={onCheckInChange}
                />
                <input type="hidden" name={`${baseName}[start_at]`} value={bookingAttrs.check_in} />
              </div>
            </label>
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label className="form-label font-size-sm text-gray-500">
              Check-Out
              <div className="input-group mt-1">
                <Flatpickr
                  className="form-control bg-white"
                  options={{
                    dateFormat: 'm/d/Y',
                    minDate: startOfDay(addDays(checkInDate || new Date(), 1)),
                    disable: disableDates,
                  }}
                  value={checkOutDate}
                  placeholder="Check-Out"
                  onChange={onCheckOutChange}
                />
                <input type="hidden" name={`${baseName}[end_at]`} value={bookingAttrs.check_out} />
              </div>
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Form.Group controlId="occupants">
            <Form.Label className="font-size-sm text-gray-500">Guests</Form.Label>
            <Form.Control
              type="number"
              placeholder="Guests"
              name={`${baseName}[occupant_count]`}
              onChange={(e) => setBookingAttrs({ ...bookingAttrs, occupants: e.target.value })}
              value={bookingAttrs.occupants}
              className={'w-100'}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <Form.Group controlId="vehicles">
            <Form.Label className="font-size-sm text-gray-500">Vehicles</Form.Label>
            <Form.Control
              type="number"
              placeholder="Vehicles"
              name={`${baseName}[vehicle_count]`}
              onChange={(e) => setBookingAttrs({ ...bookingAttrs, vehicles: e.target.value })}
              value={bookingAttrs.vehicles}
              className={'w-100'}
            />
          </Form.Group>
        </Col>
      </Row>
      {displayPrice ? pricingSummary() : null}
      <Row>
        <Col>
          <Button type="submit" block>
            Book
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BookSite
