// Hook for building a search query and pushing updates
// to browser url from a search form
//
// query can be an object or an array
// ex array:  ['park', 'rustic', 'angostura']
// ex object: { name: 'angostura', site_type: 'rustic' }
//
// Example usage:
// ** Using components from AMS not yet available in storefront **
//
//   const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
//     query: ['some filter'],
//     order: 'name'
//     asc: 1,
//     page: 1
//   })
//   const { data = { results: [] }, loading } = useFetch(searchUrl, [searchUrl])
//
//   return (
//     <>
//       <SearchForm
//         searchFilters={searchState.query}
//         onSearch={(filters) => searchDispatch({ type: 'query', value: filters })} />
//
//       <DataTable
//         rows={data.results}
//         loading={loading}
//         onSort={(name, isAcending) => searchDispatch({ type: 'order', name, isAcending })} />
//
//       {data.meta ? (
//         <Paginate
//           {...data.meta}
//           onClick={(pageNum) => searchDispatch({ type: 'page', value: pageNum })} />
//       ) : (
//         undefined
//       )}
//     </>
//   )
//

import qs from 'qs'
import { useReducer } from 'react'
const { location, history } = window

const paramsToString = (obj) => {
  const queryString = qs.stringify(obj, { arrayFormat: 'brackets' })
  return queryString.length > 0 ? `?${queryString}` : ''
}

export const useSearchQuery = (endpoint, initParams) => {
  const [searchState, searchDispatch] = useReducer((params, action) => {
    const newParams = { ...params }

    switch (action.type) {
      case 'order':
        newParams.order = action.name
        newParams.asc = action.isAcending ? 1 : 0
        break
      case 'query':
        // if the query changes, reset to page 1
        if (newParams.page !== undefined) {
          newParams.page = 1
        }

        if (Array.isArray(action.value)) {
          newParams.query = (action.value || []).sort()
        } else {
          newParams.query = action.value || {}
        }
        break
      default:
        newParams[action.type] = action.value
        break
    }

    // Update current url with each new search param without adding to history stack
    const baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
    history.replaceState({}, document.title, `${baseUrl}${paramsToString(newParams)}`)

    return newParams
  }, initParams)

  const searchQuery = paramsToString(searchState)
  const searchUrl = `${endpoint}.json${searchQuery}`
  return { searchState, searchDispatch, searchUrl, searchQuery }
}
