import React, { createContext } from 'react'
import useFetch from 'use-http'
import { useObserver, useLocalStore } from 'mobx-react-lite'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { formatCurrency } from '@utils/strings'
import CartSummary from './CartSummary'
import BookingCards from './BookingCards'
import PaymentInformation from './PaymentInformation'

interface CartReviewProps {
  reservation: any
  history: any
  cart: any
  consumables: any
  customer: any
  reservationUrl: string
  cartUrl: string
  paymentMethods: any
  paymentMethodUrl: string
}

export const CartReviewContext = createContext(null)

const CartReview: React.FC<CartReviewProps> = ({
  reservation,
  history,
  cart,
  consumables,
  customer,
  reservationUrl,
  cartUrl,
  paymentMethods,
  paymentMethodUrl,
}) => {
  const headers = {
    headers: (window as any).__STOREFRONT__.formToken,
    cachePolicy: 'no-cache',
  }
  const cartReq = useFetch(cartUrl, headers as any)
  const paymentReq = useFetch(paymentMethodUrl, headers as any)

  const store = useLocalStore(() => ({
    reservation,
    history,
    cart,
    consumables,
    customer,
    reservationUrl,
    paymentMethods,
    paymentErrors: null,
    newCard: {},
    paymentMethod: paymentMethods[0],
    useNewCard: paymentMethods.length < 1,
    get bookingItems() {
      return this.cart.cart_items.filter((item) => item.type == 'BookingItem')
    },
    get consumableItems() {
      return this.cart.cart_items.filter((item) => item.type == 'ConsumableItem')
    },
    // Bookings with associated booking items and consumable items
    get aggregatedBookings() {
      return this.reservation.bookings.map((booking) => {
        const bookingItem = this.bookingItems.find(
          (item) => parseInt(item.booking_id) == parseInt(booking.id)
        )

        const consumableItems = this.consumableItems.filter(
          (item) => parseInt(item.booking_id) == parseInt(booking.id)
        )

        return { ...booking, bookingItem, consumableItems }
      })
    },
    get siteTotal() {
      let total = this.bookingItems.reduce((acc, bookingItem) => {
        return acc + parseFloat(bookingItem.subtotal)
      }, 0)
      return formatCurrency(total)
    },
    get consumableTotal() {
      let total = this.consumableItems.reduce((acc, consumableItem) => {
        return acc + parseFloat(consumableItem.subtotal_with_quantity)
      }, 0)
      return formatCurrency(total)
    },
    get campgroundFees() {
      // booking fee for first booking in the cart. Fees only charged once, not per booking.
      const bookingItem = this.bookingItems[0]
      let fee = bookingItem ? parseFloat(bookingItem.surcharge) : 0
      return formatCurrency(fee)
    },
    get taxes() {
      let taxes = this.cart.cart_items.reduce((acc, item) => {
        return acc + parseFloat(item.tax || 0)
      }, 0)

      return formatCurrency(taxes)
    },
    get reservationTotal() {
      return formatCurrency(
        parseFloat(this.siteTotal) +
          parseFloat(this.consumableTotal) +
          parseFloat(this.campgroundFees) +
          parseFloat(this.taxes)
      )
    },
    get editedReservationTotal() {
      return this.reservationTotal - parseFloat(this.reservation.previous_payment_amount)
    },
    setCart(cart) {
      this.cart = cart
    },
    setNewCard(card) {
      this.newCard = card
    },
    setPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod
    },
    applyNewPaymentMethod(paymentMethod) {
      const methods = this.paymentMethods
      methods.unshift(paymentMethod)

      this.paymentMethods = methods
      this.useNewCard = false
      this.setPaymentMethod(paymentMethod)
      this.setNewCard({})
    },
    setPaymentErrors(errors) {
      this.paymentErrors = errors
    },
    removeFromCart: async function(uuid) {
      const item = { uuid, format: 'json' }
      const res = await cartReq.post('/remove_from_cart', item)
      if (cartReq.response.ok) {
        store.setCart(res)
      }
    },
    addPaymentMethod: async function() {
      this.setPaymentErrors(null)
      const res = await paymentReq.post('', {
        payment_method: { customer_id: customer.id, ...this.newCard },
      })
      if (paymentReq.response.ok) {
        this.applyNewPaymentMethod(res)
      } else {
        this.setPaymentErrors(res.errors)
      }
    },
    processCheckout: async function() {
      this.setPaymentErrors(null)

      // Dont process payment method for refunds
      const payment = this.editedReservationTotal < 0 ? {} : formattedPayment(this.paymentMethod)

      const res = await cartReq.post('/checkout', payment)
      if (res.error) {
        this.setPaymentErrors(res.error)
      } else {
        window.location.href = `${cartUrl}/receipt`
      }
    },
    consumableImage(consumable_id) {
      return this.consumables.find((consumable) => consumable.id == consumable_id).thumbnail_url
    },
  }))

  const formattedPayment = (paymentMethod) => {
    return {
      name_on_card: `${paymentMethod.first_name} ${paymentMethod.last_name}`,
      card_number: paymentMethod.card_number,
      expiry_date: paymentMethod.expiration,
      csv: paymentMethod.cvc,
      payment_type: 'credit',
      format: 'json',
    }
  }

  return useObserver(() => (
    <CartReviewContext.Provider value={store}>
      <Container className="py-3">
        <Row>
          <Col xs={8}>
            <Row>
              <Col>
                <BookingCards />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="outline-primary"
                  className="mr-3"
                  href={`${store.reservationUrl}/edit`}>
                  Edit Bookings
                </Button>
                <Button variant="outline-primary" href={`${cartUrl}`}>
                  Add More Add-Ons
                </Button>
              </Col>
            </Row>
            <PaymentInformation />
          </Col>
          <Col>
            <CartSummary />
          </Col>
        </Row>
      </Container>
    </CartReviewContext.Provider>
  ))
}

export default CartReview
