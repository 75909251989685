import { SearchSitesContext } from '@components/camp/sites/SearchSites'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col, Card } from 'react-bootstrap'
import classNames from 'classnames'
import { MappableSite } from '@interfaces/maps'
import NpmapIcon from '@components/shared/NpmapIcon'

interface SiteCardProps {
  // Site to display
  site: MappableSite
  site_idx: number
}

const SiteCard: React.FC<SiteCardProps> = observer(({ site, site_idx }) => {
  const store = useContext(SearchSitesContext)
  const lastSite = site_idx == store.filteredMergedSites.length - 1
  const activated = store.activeSite && store.activeSite.id == site.id

  const showHoverEffect = () => {
    store.setActiveSite(site)
  }

  const redirectToSite = () => {
    const site_url = `${store.sitesEndpoint}/${site.id}${store.siteQuery}`
    window.location.href = site_url
  }

  return (
    <Row className={classNames('bg-white', { 'pt-2': site_idx == 0 }, { 'pb-2': lastSite })}>
      <Col className="mx-2 my-0">
        <Card
          onMouseEnter={showHoverEffect}
          onClick={redirectToSite}
          className={classNames('cursor-pointer border-0', {
            'shadow-xl': activated,
            'shadow-sm': !activated,
          })}>
          <Card.Body>
            <Row>
              {site.display_image ? (
                <Col xs="auto" className="d-flex align-items-center">
                  <div
                    className="rounded bg-center-cover"
                    style={{
                      backgroundImage: `url(${site.display_image.image_url})`,
                      width: '80px',
                      height: '100px',
                    }}></div>
                </Col>
              ) : null}
              <Col className="d-flex flex-column justify-content-between">
                <div>
                  <p
                    className={classNames('h5 mb-0', {
                      'text-green-800': activated,
                    })}>
                    {site.name} &bull; {site.loop_name}
                  </p>
                  <p className="h5 font-weight-normal">{site.section_name}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p
                    className={classNames('h5 mb-0 d-flex align-items-end', {
                      'text-gray-500': !activated,
                      'text-gray-900': activated,
                    })}>
                    ${Math.round(site.min_price)} - ${Math.round(site.max_price)}&nbsp;&nbsp;
                    <span className="overline text-uppercase pb-1">per night</span>
                  </p>
                  <p className="mb-0">
                    <NpmapIcon
                      name={site.site_type_icon}
                      className={`npmap-colored-${site.site_type_icon} icon-xl p-1 mr-1`}
                    />
                  </p>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {!lastSite ? <hr></hr> : null}
      </Col>
    </Row>
  )
})

export default SiteCard
