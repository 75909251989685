import React from 'react'
import { Row, Col, Form, Button, OverlayTrigger, Popover } from 'react-bootstrap'

interface SiteFilterProps {
  // Search query
  searchInput: {
    term: string
    section_id: string
    check_in: string
    check_out: string
    occupants: string
    vehicles: string
    site_type: string
  }
  // Search function
  onSearch(query: any): void
  siteTypes: Array<string>
}

const SiteFilter: React.FC<SiteFilterProps> = ({ searchInput, onSearch, siteTypes }) => {
  const clearedFilter = { occupants: '', vehicles: '', site_type: '0' }

  const filter_count =
    (searchInput.occupants ? 1 : 0) +
    (searchInput.vehicles ? 1 : 0) +
    (searchInput.site_type != '0' ? 1 : 0)

  const popover = (
    <Popover id="site-filters">
      <Popover.Content className="m-2">
        <Row>
          <Col>
            <p className="text-uppercase overline text-gray-500">Filter By</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="occupants">
              <Form.Label>Guests</Form.Label>
              <Form.Control
                type="text"
                placeholder="Guests"
                onChange={(e) => onSearch({ occupants: e.target.value })}
                value={searchInput.occupants}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="vehicles">
              <Form.Label>Vehicles</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vehicles"
                onChange={(e) => onSearch({ vehicles: e.target.value })}
                value={searchInput.vehicles}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="site-type" className="mr-2 mb-0">
              <Form.Label>Site Type</Form.Label>
              <Form.Control
                as="select"
                value={searchInput.site_type || '0'}
                onChange={(e) => {
                  const target = e.target as HTMLSelectElement
                  onSearch({ site_type: target.value })
                }}>
                <option value="0">All Site Types</option>
                {siteTypes.map((site_type) => (
                  <option key={`site-type-${site_type}`} value={site_type}>
                    {site_type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="pt-2">
            <hr />
            <a className="link-primary cursor-pointer" onClick={() => onSearch(clearedFilter)}>
              Clear All Filters
            </a>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
      <button type="button" className="btn btn-outline-primary-light mr-2">
        More Filters {filter_count > 0 ? `(${filter_count})` : null}
      </button>
    </OverlayTrigger>
  )
}

export default SiteFilter
