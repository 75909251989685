import { EditReservationContext } from '@components/camp/reservations/EditReservation'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col, Card, Button } from 'react-bootstrap'
import classNames from 'classnames'
import BookingCartItem from './BookingCartItem'

const CartSummary: React.FC = observer(() => {
  const store = useContext(EditReservationContext)
  const previousCart = store.history ? store.history.previous_cart : null

  return (
    <Card style={{ marginTop: '-140px' }} className="shadow-sm">
      <Card.Body>
        <Row>
          <Col className="text-center">
            <p className="h5 mb-0">Reservation Details</p>
            <p className="overline text-uppercase text-gray-500">{store.reservation.park_name}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            {store.aggregatedBookings.map((booking, idx) => (
              <BookingCartItem
                booking={booking}
                firstBooking={idx == 0}
                subtotal={store.bookingSubtotal(booking.id)}
                key={`booking-${booking.id}`}
              />
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="m-3 px-0 py-3 border-top border-bottom">
            <div className="d-flex justify-content-between">
              <p className="mb-0">Site Total</p>
              <p
                className={classNames('mb-0', {
                  'text-red-600': previousCart && previousCart.booking_subtotal != store.siteTotal,
                })}>
                ${store.siteTotal}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Add-Ons Total</p>
              <p
                className={classNames('mb-0', {
                  'text-red-600':
                    previousCart && previousCart.consumable_subtotal != store.consumableTotal,
                })}>
                ${store.consumableTotal}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Campground Fees</p>
              <p className="mb-0">${store.campgroundFees}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Taxes</p>
              <p
                className={classNames('mb-0', {
                  'text-red-600': previousCart && previousCart.tax != store.taxes,
                })}>
                ${store.taxes}
              </p>
            </div>
            {store.reservation.is_draft ? (
              <>
                <div className="d-flex justify-content-between mt-2">
                  <p className="h5 mb-0">Amount Paid</p>
                  <p className="h5 mb-0">${store.reservation.previous_payment_amount}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="h5 mb-0">Amount Due</p>
                  <p
                    className={classNames('h5 mb-0', {
                      'text-red-600':
                        previousCart && previousCart.grand_total != store.reservationTotal,
                    })}>
                    {store.editedReservationTotal}
                  </p>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-between">
                <p className="h5 mb-0">Reservation Total</p>
                <p className="h5 mb-0">${store.reservationTotal}</p>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" variant="primary" block>
              Next
            </Button>
            <a
              href={store.reservationUrl}
              data-method="delete"
              className="btn btn-block btn-outline-primary mt-3">
              {store.reservation.is_draft ? 'Cancel Edit Reservation' : 'Cancel Reservation'}
            </a>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
})

export default CartSummary
