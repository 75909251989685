import L from 'leaflet'
import React from 'react'

export const parkIcon = () => {
  return L.icon({
    iconUrl: '/assets/icons/park-icon.svg',
    iconAnchor: [20, 40],
    popupAnchor: [-1, -29],
  })
}

export const circleIcon = (colorVariant: string): L.DivIcon => {
  const iconSettings = {
    iconSvg: `<svg viewBox="0 0 30 30" class="feather-icon icon-xl {colorVariant}" role="img" focusable="false">
        <use href="{featherAssetPath}#circle" aria-hidden="true"></use>
      </svg>`,
    colorVariant: colorVariant,
    featherAssetPath: (window as any).__STOREFRONT__.featherSvgPath,
  }
  return L.divIcon({
    className: 'camp-div-icon',
    html: L.Util.template(iconSettings.iconSvg, iconSettings),
    iconSize: [24, 24],
    iconAnchor: [12, 12],
  })
}

export const popupIcon = (iconName: string, colorVariant: string): L.DivIcon => {
  const iconSettings = {
    iconSvg: `<img src="/assets/icons/popup-pin.svg" style="position: absolute;">
      <svg viewBox="0 0 30 30" class="npmap-icon icon-xl {colorVariant}"
           role="img" focusable="false" style="position: absolute; top: 10px; left: 13px;">
        <use href="{npmapAssetPath}#icon-{iconName}" aria-hidden="true"></use>
      </svg>`,
    iconName: iconName,
    colorVariant: colorVariant,
    npmapAssetPath: (window as any).__STOREFRONT__.npmapSvgPath,
  }
  return L.divIcon({
    className: 'camp-div-icon',
    html: L.Util.template(iconSettings.iconSvg, iconSettings),
    iconSize: [50, 58],
    iconAnchor: [25, 45],
    popupAnchor: [-1, -30],
  })
}

export const facilityIcon = (iconName: string, active: boolean): L.DivIcon => {
  const iconSettings = {
    iconSvg: `<svg viewBox="0 0 30 30" class="feather-icon icon-xl facility-icon-circle{activeClass}" role="img" focusable="false">
        <use href="{featherAssetPath}#circle" aria-hidden="true"></use>
      </svg>
      <svg viewBox="0 0 30 30" class="npmap-icon icon-lg"
           role="img" focusable="false" style="position: absolute; top: 6px; left: 6px;">
        <use href="{npmapAssetPath}#icon-{iconName}" aria-hidden="true"></use>
      </svg>`,
    iconName: iconName,
    activeClass: active ? '-active' : '',
    featherAssetPath: (window as any).__STOREFRONT__.featherSvgPath,
    npmapAssetPath: (window as any).__STOREFRONT__.npmapSvgPath,
  }
  return L.divIcon({
    className: 'camp-div-icon',
    html: L.Util.template(iconSettings.iconSvg, iconSettings),
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -8],
  })
}

const siteColors = {
  cabin: 'fill-green-200',
  campground: 'fill-pink-500',
  campsite: 'fill-teal-300',
  lodging: 'fill-blue-600',
  'rv-campground': 'fill-purple-500',
  sunny: 'fill-yellow-500',
  'trailer-site': 'fill-orange-300',
}

export const iconForSite = (site: any): L.DivIcon => {
  if (!site.available) {
    return circleIcon('fill-gray-400')
  } else if (site.excluded) {
    return circleIcon('fill-gray-500')
  } else if (site.site_type_icon) {
    return circleIcon(siteColors[site.site_type_icon])
  } else {
    return circleIcon('fill-green-400')
  }
}

export const popupIconForSite = (available: boolean, icon: string): L.DivIcon => {
  if (!available) {
    return popupIcon(icon, 'fill-gray-400')
  } else if (icon) {
    return popupIcon(icon, siteColors[icon])
  } else {
    return popupIcon(icon, 'fill-green-400')
  }
}
