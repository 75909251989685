import qs from 'qs'
import useFetch from 'use-http'
import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSearchQuery } from '@hooks/useSearchQuery'
import SearchForm from './SearchForm'
import ParkMapSidebar from './ParkMapSidebar'
import ParkMap from './ParkMap'

interface SearchParksProps {
  // String of endpoint to request park data
  endpoint: string
}

const SearchParks: React.FC<SearchParksProps> = ({ endpoint }) => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { searchState, searchDispatch, searchUrl } = useSearchQuery(endpoint, {
    query: params.query || {},
  })

  const { get, loading } = useFetch(searchUrl)
  const [parks, setParks] = useState([])
  const [activePark, setActivePark] = useState(null)

  useEffect(() => {
    const fetchParks = async () => {
      const res = await get()
      setParks(res)
    }

    fetchParks()
  }, [get, searchUrl])

  return (
    <Container fluid>
      <SearchForm
        initialQuery={params.query}
        onSearch={(query) => searchDispatch({ type: 'query', value: query })}
      />
      <Row>
        <Col xs={3} className="bg-gray-100 park-map-sidebar">
          <ParkMapSidebar
            parks={parks}
            base_url={endpoint}
            activePark={activePark}
            setActivePark={setActivePark}
          />
        </Col>
        <Col className="px-0">
          <ParkMap parks={parks} activePark={activePark} setActivePark={setActivePark} />
        </Col>
      </Row>
    </Container>
  )
}

export default SearchParks
