import { CartReviewContext } from '@components/camp/reservations/Cart/Review'
import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Row, Col, Card, Button, Alert } from 'react-bootstrap'
import { parse, format } from 'date-fns'
import classNames from 'classnames'

interface newCardType {
  customer_id: number
  first_name?: string
  last_name?: string
  card_number?: string
  expiration?: string
  cvc?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  postal_code?: string
}

const NewPaymentForm: React.FC = observer(() => {
  const store = useContext(CartReviewContext)

  return (
    <Row className="border-top mt-2 pt-2">
      <Col xs={12}>
        <h5 className="mt-2">Add New Payment Method</h5>
      </Col>
      <Col xs={6}>
        <Row>
          <Col xs={12}>
            <p className="overline text-uppercase text-gray-500 my-3">Card Info</p>
          </Col>
          <Col xs={12}>
            <Form.Group controlId={`first-name`}>
              <Form.Label className="font-size-sm mb-0">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, first_name: e.target.value })
                }}
                value={store.newCard.first_name || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId={`last-name`}>
              <Form.Label className="font-size-sm mb-0">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, last_name: e.target.value })
                }}
                value={store.newCard.last_name || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={10}>
            <Form.Group controlId={`card-number`}>
              <Form.Label className="font-size-sm mb-0">Card Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Card Number"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, card_number: e.target.value })
                }}
                value={store.newCard.card_number || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId={`expiration`}>
              <Form.Label className="font-size-sm mb-0">Expiry Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="MM/YYYY"
                maxLength={7}
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, expiration: e.target.value })
                }}
                value={store.newCard.expiration || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId={`cvc`}>
              <Form.Label className="font-size-sm mb-0">CVC</Form.Label>
              <Form.Control
                type="text"
                placeholder="CVC"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, cvc: e.target.value })
                }}
                value={store.newCard.cvc || ''}
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Row>
          <Col xs={12}>
            <p className="overline text-uppercase text-gray-500 my-3">Billing Address</p>
          </Col>
          <Col xs={12}>
            <Form.Group controlId={`address1`}>
              <Form.Label className="font-size-sm mb-0">Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, address1: e.target.value })
                }}
                value={store.newCard.address1 || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId={`address2`}>
              <Form.Label className="font-size-sm mb-0">Apt</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, address2: e.target.value })
                }}
                value={store.newCard.address2 || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId={`city`}>
              <Form.Label className="font-size-sm mb-0">City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, city: e.target.value })
                }}
                value={store.newCard.city || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId={`state`}>
              <Form.Label className="font-size-sm mb-0">State</Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, state: e.target.value })
                }}
                value={store.newCard.state || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={5}>
            <Form.Group controlId={`postal-code`}>
              <Form.Label className="font-size-sm mb-0">Zip Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zip Code"
                onChange={(e) => {
                  store.setNewCard({ ...store.newCard, postal_code: e.target.value })
                }}
                value={store.newCard.postal_code || ''}
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col xs={6}>
        <Button variant="primary" className="mt-3" block onClick={store.addPaymentMethod}>
          Add New Payment Method
        </Button>
      </Col>
      {/* cant cancel if they have no other payment methods */}
      {!(store.paymentMethods.length < 1) ? (
        <Col xs={6}>
          <Button
            variant="outline-primary"
            className="mt-3"
            onClick={() => {
              store.setPaymentErrors(null)
              store.setNewCard({})
              store.useNewCard = false
            }}>
            Cancel
          </Button>
        </Col>
      ) : null}
    </Row>
  )
})

export default NewPaymentForm
