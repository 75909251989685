import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'

interface SearchFormProps {
  // Search query
  initialQuery: {
    term: string
  }
  // Search function
  onSearch(query: any): void
}

const SearchForm: React.FC<SearchFormProps> = ({ initialQuery, onSearch }) => {
  const [searchInput, setSearchInput] = useState(initialQuery || { term: '' })

  const handleSubmit = (e) => {
    e.preventDefault()
    onSearch(searchInput)
  }

  return (
    <Row className="bg-gray-800">
      <Col sm={6} lg={3} className="pt-3">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="park_query">
            <Form.Control
              type="text"
              placeholder="Find a Park"
              value={searchInput.term || ''}
              onChange={(e) => setSearchInput({ ...searchInput, term: e.target.value })}
            />
          </Form.Group>
        </Form>
      </Col>
      {/* <Col className="pt-3">More Filters</Col> */}
      <Col className="pt-3">
        <button type="button" className="btn btn-primary-light" onClick={handleSubmit}>
          Search
        </button>
      </Col>
    </Row>
  )
}

export default SearchForm
