import { SearchSitesContext } from '@components/camp/sites/SearchSites'
import React, { useState, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col, Form } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { format, startOfDay, addDays } from 'date-fns'
import SiteFilter from './SiteFilter'

const defaultQuery = {
  term: '',
  section_id: '0',
  check_in: '',
  check_out: '',
  occupants: '',
  vehicles: '',
  site_type: '0',
}

const SearchForm: React.FC = observer(() => {
  const store = useContext(SearchSitesContext)

  // State of filters before submitting the search form
  const [searchInput, setSearchInput] = useState(defaultQuery)

  const handleSubmit = (e) => {
    e.preventDefault()
    store.setSearchFilter(searchInput)
  }

  return (
    <Row className="bg-gray-800">
      <Col className="py-3">
        <Form inline onSubmit={handleSubmit}>
          <Form.Group controlId="site-section" className="mr-2">
            <Form.Control
              as="select"
              value={searchInput.section_id || '0'}
              onChange={(e) => {
                const target = e.target as HTMLSelectElement
                setSearchInput({ ...searchInput, section_id: target.value })
              }}>
              <option value="0">All Campgrounds</option>
              {store.sections.map((section) => (
                <option key={`section-${section.id}`} value={section.id}>
                  {section.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="form-group mr-2">
            <div className="input-group">
              <Flatpickr
                className="form-control bg-white"
                options={{ dateFormat: 'm/d/Y', minDate: startOfDay(new Date()) }}
                value={searchInput.check_in}
                placeholder="Check-In"
                onChange={(dates) => {
                  const dateString = format(dates[0], 'LL/dd/yyyy')
                  setSearchInput({ ...searchInput, check_in: dateString })
                }}
              />
            </div>
          </div>
          <div className="form-group mr-2">
            <div className="input-group">
              <Flatpickr
                className="form-control bg-white"
                options={{ dateFormat: 'm/d/Y', minDate: startOfDay(addDays(new Date(), 1)) }}
                value={searchInput.check_out}
                placeholder="Check-Out"
                onChange={(dates) => {
                  const dateString = format(dates[0], 'LL/dd/yyyy')
                  setSearchInput({ ...searchInput, check_out: dateString })
                }}
              />
            </div>
          </div>
          <SiteFilter
            searchInput={searchInput}
            onSearch={(searchField) => setSearchInput({ ...searchInput, ...searchField })}
            siteTypes={store.siteTypes}
          />
          <button type="submit" className="btn btn-primary-light">
            Search
          </button>
        </Form>
      </Col>
    </Row>
  )
})

export default SearchForm
