import { EditReservationContext } from '@components/camp/reservations/EditReservation'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Row, Col, Card, Alert } from 'react-bootstrap'
import { formatISO, parseISO, addDays, isWithinInterval, startOfDay } from 'date-fns'
import classNames from 'classnames'
import Flatpickr from 'react-flatpickr'
import SiteBadge from '@components/camp/sites/SiteBadge'

const BookingCards: React.FC = observer(() => {
  const store = useContext(EditReservationContext)

  const bookingError = (booking_id) => {
    const errors = store.errorsForBooking(booking_id)
    return errors ? (
      <Row className="mt-2">
        <Col>
          <Alert key={`booking-errors-${booking_id}`} variant={'danger'}>
            <ul className="mb-0 pl-3">
              {errors.map((error, idx) => (
                <li className="font-size-sm" key={`error-${idx}`}>
                  {error}
                </li>
              ))}
            </ul>
          </Alert>
        </Col>
      </Row>
    ) : null
  }

  return store.aggregatedBookings.map((booking, idx) => {
    const baseName = `camp_reservation[bookings_attributes][${idx}]`
    const occupantBaseName = `${baseName}[primary_occupant_attributes]`
    // build_association is not working for has_one associations
    // so setting an empty object for now
    const occupant = booking.primary_occupant || {}

    const startAt = parseISO(booking.start_at)
    const endAt = parseISO(booking.end_at)

    const bookedDates = booking.unavailable_dates_for_site.map((str) => parseISO(str))

    return (
      <Card className="mb-3 border-0" key={`booking-card-${booking.id}`}>
        <input type="hidden" name={`${baseName}[id]`} value={booking.id} />
        {occupant.id ? (
          <input type="hidden" name={`${occupantBaseName}[id]`} value={occupant.id} />
        ) : null}
        <Card.Body>
          <Row>
            <Col className="border-bottom d-flex align-items-center justify-content-between pb-3">
              <div>
                <p className="h5 mb-0">
                  {booking.site_name} &bull; {booking.loop_name}
                </p>
                <p className="font-size-sm mb-0">{booking.section_name}</p>
              </div>
              <SiteBadge
                iconName={booking.site_type_icon}
                siteTypeName={booking.site_type_name}
                textClass="font-size-sm"
              />
            </Col>
          </Row>
          {bookingError(booking.id)}
          <Row className="mt-3">
            <Col xs={6} className="d-flex align-items-start flex-column">
              <p className="overline text-uppercase text-gray-500">Details</p>
              <Row>
                <Col>
                  <div className="form-group">
                    <label className="form-label font-size-sm text-gray-500">
                      Check-In
                      <div className="input-group">
                        <Flatpickr
                          className="form-control bg-white"
                          options={{
                            dateFormat: 'm/d/Y',
                            minDate: startOfDay(new Date()),
                            disable: bookedDates,
                          }}
                          value={startAt}
                          placeholder="Check-In"
                          onChange={(dates) => {
                            const date = formatISO(dates[0], { representation: 'date' })
                            store.updateBooking(booking.id, 'start_at', date)
                          }}
                        />
                        <input
                          type="hidden"
                          name={`${baseName}[start_at]`}
                          value={booking.start_at}
                        />
                      </div>
                    </label>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label className="form-label font-size-sm text-gray-500">
                      Check-Out
                      <div className="input-group">
                        <Flatpickr
                          className="form-control bg-white"
                          options={{
                            dateFormat: 'm/d/Y',
                            minDate: startOfDay(addDays(startAt || new Date(), 1)),
                            disable: bookedDates,
                          }}
                          value={endAt}
                          placeholder="Check-In"
                          onChange={(dates) => {
                            const date = formatISO(dates[0], { representation: 'date' })
                            store.updateBooking(booking.id, 'end_at', date)
                          }}
                        />
                        <input type="hidden" name={`${baseName}[end_at]`} value={booking.end_at} />
                      </div>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mb-auto">
                <Col>
                  <Form.Group controlId={`occupants-${idx}`}>
                    <Form.Label className="font-size-sm text-gray-500 mb-0">Guests</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Guests"
                      name={`${baseName}[occupant_count]`}
                      onChange={(e) => {
                        store.updateBooking(booking.id, 'occupant_count', e.target.value)
                      }}
                      value={booking.occupant_count}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`vehicles-${idx}`}>
                    <Form.Label className="font-size-sm text-gray-500 mb-0">Vehicles</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Vehicles"
                      name={`${baseName}[vehicle_count]`}
                      onChange={(e) => {
                        store.updateBooking(booking.id, 'vehicle_count', e.target.value)
                      }}
                      value={booking.vehicle_count}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="w-100">
                <Col>
                  <a
                    href={`/campgrounds/bookings/${booking.id}`}
                    data-method="delete"
                    className="btn btn-block btn-outline-primary">
                    Remove Booking
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <p className="overline text-uppercase text-gray-500">Primary Occupant</p>
              <Row>
                <Col>
                  <div
                    className={classNames(
                      'p-2 mb-2',
                      { 'bg-gray-100': !occupant.customer_id },
                      { 'bg-green-100': occupant.customer_id }
                    )}>
                    <Form.Check
                      type="checkbox"
                      label="I&rsquo;m the primary occupant."
                      id={`primary-occupant-${idx}`}
                      checked={occupant.customer_id ? true : false}
                      onChange={(e) => store.updateBookingOccupant(booking.id, e.target.checked)}
                    />
                  </div>
                  {occupant.customer_id ? (
                    <input
                      type="hidden"
                      name={`${occupantBaseName}[customer_id]`}
                      value={occupant.customer_id}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId={`first-name-${idx}`}>
                    <Form.Label className="font-size-sm mb-0">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name={`${occupantBaseName}[first_name]`}
                      onChange={(e) => {
                        occupant.first_name = e.target.value
                      }}
                      value={occupant.first_name || ''}
                      disabled={occupant.customer_id}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId={`last-name-${idx}`}>
                    <Form.Label className="font-size-sm mb-0">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name={`${occupantBaseName}[last_name]`}
                      onChange={(e) => {
                        occupant.last_name = e.target.value
                      }}
                      value={occupant.last_name || ''}
                      disabled={occupant.customer_id}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId={`email-${idx}`}>
                    <Form.Label className="font-size-sm mb-0">Email</Form.Label>
                    <Form.Control
                      type="text"
                      name={`${occupantBaseName}[email]`}
                      onChange={(e) => {
                        occupant.email = e.target.value
                      }}
                      value={occupant.email || ''}
                      disabled={occupant.customer_id}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId={`phone-number-${idx}`}>
                    <Form.Label className="font-size-sm mb-0">Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name={`${occupantBaseName}[phone_number]`}
                      onChange={(e) => {
                        occupant.phone_number = e.target.value
                      }}
                      value={occupant.phone_number || ''}
                      disabled={occupant.customer_id}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  })
})

export default BookingCards
