import { subDays } from 'date-fns'
import {
  EventActivity,
  EventsProgram,
  ProgramEvent,
  EventWindowCalculation,
  EventWindowCalculationObject,
} from '@interfaces/events'
import { parseDateString } from '@utils/strings'

export const isWaitlistSupported = (p: EventsProgram): boolean => p.event_settings.supports_waitlist

export const defaultEventName = (p: EventsProgram, savedName: string = null): string => {
  switch (p.event_settings.event_name_setting) {
    case 'program_name':
      return p.name
    case 'configured_name':
      let result = p.event_name_options[0]

      // When editing an event (e.g. when re-submitting approval),
      // a name should already be chosen, so we set that here from `event_name_options`.
      if (savedName && savedName.length > 0) {
        // If the existing name is no longer in the list of options,
        // let `result` use the first option.
        const savedNameOption = p.event_name_options.find((o) => o === savedName)
        if (savedNameOption) {
          result = savedNameOption
        }
      }

      return result
    default:
      return ''
  }
}

const calculateWaitlist = (p: EventsProgram, capacity: number): number =>
  Math.floor((p.event_settings.wait_list_max_requests * capacity) / 100)

export const calculateMaxCapacity = (p: EventsProgram, capacity: number): number =>
  isWaitlistSupported(p) ? calculateWaitlist(p, capacity) : 0

const calculateVisibilityWindow = (
  windowSetting: number,
  event: ProgramEvent,
  eventActivity: EventActivity
): EventWindowCalculation => {
  // Let initial values stay null
  const values = { startAt: null, endAt: null }
  const isVisibleNow = windowSetting === 0

  // If the record is persisted or dirty, don't recalculate
  if (event.visibility_start_at || event.visibility_end_at) {
    values.startAt = event.visibility_start_at
    values.endAt = event.visibility_end_at
  } else if (eventActivity) {
    // If the window is 0, set start date to today
    // Else, if we have a start date, subtract the two
    const parsedStartAt = parseDateString(eventActivity.start_at)
    values.startAt = (isVisibleNow
      ? new Date()
      : subDays(parsedStartAt, windowSetting)
    ).toISOString()
    values.endAt = eventActivity ? eventActivity.start_at : null
  }

  return values
}

const calculateRegistrationWindow = (
  windowSetting: number,
  event: ProgramEvent,
  eventActivity: EventActivity,
  startAt: string
): EventWindowCalculation => {
  const values = { startAt: null, endAt: null }

  if (event.registration_start_at || event.registration_end_at) {
    values.startAt = event.registration_start_at
    values.endAt = event.registration_end_at
  } else if (eventActivity) {
    const parsedStartAt = parseDateString(eventActivity.start_at)
    values.startAt = startAt
    values.endAt = eventActivity.start_at
      ? subDays(parsedStartAt, windowSetting).toISOString()
      : null
  }
  return values
}

const calculateCancellationWindow = (
  windowSetting: number,
  event: ProgramEvent,
  eventActivity: EventActivity,
  startAt: string
): EventWindowCalculation => {
  const values = { startAt: null, endAt: null }

  if (event.cancellation_start_at || event.cancellation_end_at) {
    values.startAt = event.cancellation_start_at
    values.endAt = event.cancellation_end_at
  } else if (eventActivity) {
    const parsedStartAt = parseDateString(eventActivity.start_at)
    values.startAt = startAt
    values.endAt = eventActivity.start_at
      ? subDays(parsedStartAt, windowSetting).toISOString()
      : null
  }

  return values
}

const calculatePersisted = (event: ProgramEvent): boolean =>
  !!(
    event.visibility_start_at ||
    event.visibility_end_at ||
    event.registration_start_at ||
    event.registration_end_at ||
    event.cancellation_start_at ||
    event.cancellation_end_at
  )

export const calculateWindowDates = (
  program: EventsProgram,
  event: ProgramEvent,
  eventActivities: EventActivity[]
): EventWindowCalculationObject => {
  const eventActivity = eventActivities.find((activity) => !activity._destroy)

  const visibility = calculateVisibilityWindow(
    program.event_settings.event_visibility_window || 0,
    event,
    eventActivity
  )
  const registration = calculateRegistrationWindow(
    program.registration_settings.close_registration_window || 0,
    event,
    eventActivity,
    visibility.startAt
  )
  const cancellation = calculateCancellationWindow(
    program.registration_settings.cancel_registration_window || 0,
    event,
    eventActivity,
    visibility.startAt
  )
  const persisted = calculatePersisted(event)

  return { visibility, registration, cancellation, persisted }
}

export const canChangeAgeSettings = (event: ProgramEvent, program: EventsProgram): boolean => {
  return (
    // Cant edit age restrictions after event has been saved
    // unless event has not been activated
    (!event.id || event.status == 'activation_denied') &&
    // If anything but default the age settings are hidden
    program.registration_settings.age_restriction == 'default' &&
    // Instructors must have the ability to override
    program.instructor_overridable
  )
}

export const getDefaultAgeRestrictions = (event: ProgramEvent, program: EventsProgram) => {
  const ageSettings = {
    minAge: event.min_age || program.registration_settings.min_age || '',
    minAgeDateCalculation:
      event.min_age_date_calculation ||
      program.registration_settings.min_age_date_calculation ||
      '',
    minAgeDisplayText:
      event.min_age_display_text || program.registration_settings.min_age_display_text || '',
    maxAge: event.max_age || program.registration_settings.max_age || '',
    maxAgeDateCalculation:
      event.max_age_date_calculation ||
      program.registration_settings.max_age_date_calculation ||
      '',
    maxAgeDisplayText:
      event.max_age_display_text || program.registration_settings.max_age_display_text || '',
  }

  return ageSettings
}
