import { SearchSitesContext } from '@components/camp/sites/SearchSites'
import ReactDOMServer from 'react-dom/server'
import React, { useContext } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import L from 'leaflet'
import { Card, Row, Col } from 'react-bootstrap'
import FeatherIcon from '@components/shared/FeatherIcon'
import * as mapLayers from '@components/map/mapLayers'
import * as mapIcons from '@components/map/mapIcons'
import { MappableSite } from '@interfaces/maps'
import useDidMount from '@hooks/useDidMount'

type SiteMarker = {
  site_id: number
  activated: boolean
  marker: L.Marker
  siteIcon: L.Icon
  popupIcon: L.Icon
}

// Hardcoded default South Dakota
// coordinates and zoom level
const defaultLat = 44
const defaultLng = -100
const defaultZoom = 7
const defaultCoords = new L.LatLng(defaultLat, defaultLng)
const mapId = `leaflet-map-${Date.now()}`

const refreshMarkers = (
  map: L.Map,
  markers: any,
  sites: Array<MappableSite>,
  selectedSectionId: number,
  bindMarkerCallbacks
) => {
  const newMarkers = []
  const markersForZoom = []

  markers.forEach((siteMarker) => {
    map.removeLayer(siteMarker.marker)
  })

  sites.forEach((site) => {
    if (site.latitude && site.longitude) {
      let coords = new L.LatLng(parseFloat(site.latitude), parseFloat(site.longitude))
      let siteIcon = mapIcons.iconForSite(site)
      let popupIcon = mapIcons.popupIconForSite(site.available, site.site_type_icon)

      let marker = L.marker(coords, {
        icon: siteIcon,
      }).addTo(map)

      bindMarkerCallbacks(site, marker)
      newMarkers.push({
        site_id: site.id,
        activated: false,
        marker: marker,
        siteIcon: siteIcon,
        popupIcon: popupIcon,
      })

      if (!selectedSectionId || selectedSectionId == 0 || selectedSectionId == site.section_id) {
        markersForZoom.push(marker)
      }
    }
  })

  // Set map zoom to bounds of markers
  const bounds = L.featureGroup(markersForZoom).getBounds()
  if (bounds.isValid()) {
    map.flyToBounds(bounds, {
      padding: [10, 10],
      maxZoom: 17,
    })
  }

  return newMarkers
}

const sitePopup = (site: MappableSite, endpoint, siteQuery) => {
  return `<div class="card border-0 park-popup">
    <div class="row no-gutters">
      ${
        site.display_image
          ? `<div class="col-auto">
          <div class="park-image bg-center-cover"
               style="background-image: url(${site.display_image.image_url});">
          </div>
        </div>`
          : ''
      }
      <div class="col d-flex align-items-center">
        <p class="overline text-uppercase text-gray-900 ${site.display_image ? 'mx-3' : 'm-3'}">
          ${site.name} &bull; ${site.loop_name || ''}<br>${site.section_name || ''}
        </p>
      </div>
    </div>
    <div class="row no-gutters rounded-bottom bg-gray-800 d-flex align-items-center">
      <div class="col-auto m-2">
        <a class="link-light h-25 ml-2" href="${endpoint}/${site.id}${siteQuery}">
          View
        </a>
      </div>
      ${
        site.available
          ? ''
          : `<div class="col m-2">
              <p class="btn-text text-gray-500">
                Unavailable
              </p>
            </div>`
      }
    </div>
  </div>`
}

const activateMarker = (marker: SiteMarker) => {
  marker.activated = true
  marker.marker.setIcon(marker.popupIcon)
  marker.marker.openPopup()
}

const deactivateMarker = (marker: SiteMarker) => {
  marker.activated = false
  marker.marker.setIcon(marker.siteIcon)
  marker.marker.closePopup()
}

const facilityPopup = (facility: any) => {
  return `<div class="card border-0">
    <div class="row no-gutters">
      <div class="col d-flex align-items-center">
        <p class="overline text-uppercase text-gray-900 mx-2 my-1">
          ${facility.name}
        </p>
      </div>
    </div>
  </div>`
}

const initializeSections = (map: L.Map, sections: any) => {
  sections.forEach((section) => {
    if (section.latitude && section.longitude) {
      const coords = new L.LatLng(parseFloat(section.latitude), parseFloat(section.longitude))
      const sectionHtml = `<p class="overline text-gray-600 text-uppercase">${section.name}</p>`

      L.marker(coords, {
        icon: L.divIcon({
          className: 'section-label text-center',
          html: sectionHtml,
          iconSize: [150, 80],
          iconAnchor: [75, 40],
        }),
      }).addTo(map)
    }
  })
}

const initializeFacilities = (map: L.Map, facilities: any) => {
  facilities.forEach((facilityLocation) => {
    if (facilityLocation.latitude && facilityLocation.longitude) {
      const facility = facilityLocation.facility
      const activeIcon = mapIcons.facilityIcon(facility.icon_name, true)
      const inactiveIcon = mapIcons.facilityIcon(facility.icon_name, false)

      const coords = new L.LatLng(
        parseFloat(facilityLocation.latitude),
        parseFloat(facilityLocation.longitude)
      )

      const facilityMarker = L.marker(coords, {
        icon: inactiveIcon,
      }).addTo(map)

      facilityMarker.bindPopup(facilityPopup(facility), {
        closeButton: false,
      })

      // mouseover event was firing continuously for some reason
      // Changes to fire once, then reset event on mouseout.
      facilityMarker.once('mouseover', (e) => {
        facilityMarker.setIcon(activeIcon)
        facilityMarker.openPopup()
      })

      facilityMarker.on('mouseout', (e) => {
        facilityMarker.setIcon(inactiveIcon)
        facilityMarker.closePopup()

        facilityMarker.once('mouseover', (e) => {
          facilityMarker.setIcon(activeIcon)
          facilityMarker.openPopup()
        })
      })
    }
  })
}

const initializeLegend = (map: L.Map) => {
  const legend = new (L.Control.extend({
    options: { position: 'bottomright' },
  }))()

  legend.onAdd = function(map) {
    const div = L.DomUtil.create('div', 'legend')

    const legendIcons = [
      { name: 'Backpack', fillColor: 'fill-blue-300' },
      { name: 'RV', fillColor: 'fill-purple-500' },
      { name: 'Cabin', fillColor: 'fill-green-200' },
      { name: 'Tent', fillColor: 'fill-teal-300' },
      { name: 'Camper', fillColor: 'fill-orange-300' },
      { name: 'Group Site', fillColor: 'fill-pink-500' },
      { name: 'Day Use', fillColor: 'fill-yellow-500' },
      { name: 'Lodging', fillColor: 'fill-blue-600' },
    ]

    const divContent = ReactDOMServer.renderToStaticMarkup(
      <Row>
        <Col>
          <Card style={{ maxWidth: '280px' }}>
            <Card.Body className="py-2">
              <Row className="pl-1 mb-2">
                {legendIcons.map((icon, idx) => (
                  <Col xs={6} className="px-0" key={`legend-${idx}`}>
                    <p className="font-size-sm mb-1">
                      <FeatherIcon name="circle" className={`${icon.fillColor} mr-2 sw-3`} />
                      {icon.name}
                    </p>
                  </Col>
                ))}
              </Row>
              <Row className="border-top pl-1">
                <Col xs={6} className="mt-2 px-0">
                  <p className="font-size-sm mb-1">
                    <FeatherIcon name="circle" className={`fill-gray-400 mr-2 sw-3`} />
                    Unavailable
                  </p>
                </Col>
                <Col xs={6} className="mt-2 px-0">
                  <p className="font-size-sm mb-1">
                    <FeatherIcon name="circle" className={`fill-gray-500 mr-2 sw-3`} />
                    Outside Filters
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )

    div.innerHTML = divContent
    return div
  }
  legend.addTo(map)
}

const SiteMap: React.FC = observer(() => {
  const store = useContext(SearchSitesContext)

  const bindMarkerCallbacks = (site, marker) => {
    marker.bindPopup(sitePopup(site, store.sitesEndpoint, store.siteQuery), {
      className: 'wide-popup',
    })
    marker.on('mouseover', (e) => {
      store.markers.forEach((siteMarker) => {
        // Set previously activated marker to green dot
        if (siteMarker.activated && siteMarker.site_id != site.id) {
          deactivateMarker(siteMarker)
        }
      })

      store.setActiveSite(site)
    })
  }

  // Initialize Leaflet Map
  useDidMount(() => {
    const newMap = L.map(mapId, {
      attributionControl: false, // Overide so we can set prefix: false
      zoomControl: false, // Override so we can place in corner
    }).setView(defaultCoords, defaultZoom)

    mapLayers.setupLayersAndControls(newMap)
    initializeSections(newMap, store.sections)
    initializeFacilities(newMap, store.facilities)
    initializeLegend(newMap)

    const newMarkers = refreshMarkers(
      newMap,
      store.markers,
      store.mergedSites,
      store.searchFilter.section_id,
      bindMarkerCallbacks
    )

    store.setMap(newMap)
    store.setMarkers(newMarkers)
  })

  reaction(
    () => store.activeSite,
    (activeSite) => {
      // Dont change activated markers until a new
      // site is activated
      if (activeSite) {
        store.markers.forEach((siteMarker) => {
          // Set previously activated marker icon to default
          if (siteMarker.activated && activeSite.id != siteMarker.site_id) {
            deactivateMarker(siteMarker)
          }

          // Activate marker and set icon to park
          if (!siteMarker.activated && activeSite.id == siteMarker.site_id) {
            activateMarker(siteMarker)
          }
        })
      }
    }
  )

  reaction(
    () => store.searchFilter,
    (searchFilter) => {
      const newMarkers = refreshMarkers(
        store.map,
        store.markers,
        store.mergedSites,
        store.searchFilter.section_id,
        bindMarkerCallbacks
      )
      store.setMarkers(newMarkers)
    }
  )

  return (
    <div className="ke-map interactive-map site-map">
      <div id={mapId} className="h-100"></div>
    </div>
  )
})

export default SiteMap
