import React, { useState } from 'react'
import L from 'leaflet'
import * as mapLayers from '@components/map/mapLayers'
import * as mapIcons from '@components/map/mapIcons'
import { MappablePark } from '@interfaces/maps'
import useDidUpdate from '@hooks/useDidUpdate'
import useDidMount from '@hooks/useDidMount'

type ParkMapProps = {
  parks: Array<MappablePark>
  activePark: MappablePark
  setActivePark(park: MappablePark): void
}

type ParkMarker = {
  park_id: number
  activated: boolean
  marker: L.Marker
}

const ParkMap: React.FC<ParkMapProps> = ({ parks, activePark, setActivePark }) => {
  const mapId = `leaflet-map-${Date.now()}`
  const [map, setMap] = useState<L.Map>(null)
  const [markers, setMarkers] = useState<Array<ParkMarker>>([])

  // Initialize Leaflet Map
  useDidMount(() => {
    // Hardcoded default South Dakota
    // coordinates and zoom level
    const defaultLat = 44
    const defaultLng = -100
    const defaultZoom = 7
    const defaultCoords = new L.LatLng(defaultLat, defaultLng)

    const map = L.map(mapId, {
      attributionControl: false, // Overide so we can set prefix: false
      zoomControl: false, // Override so we can place in corner
    }).setView(defaultCoords, defaultZoom)

    mapLayers.setupLayersAndControls(map)
    refreshMarkers(map)
    setMap(map)
  })

  // Create markers for park search results
  useDidUpdate(() => {
    refreshMarkers(map)
  }, [parks])

  // Toggle park marker active/inactive state
  useDidUpdate(() => {
    markers.forEach((parkMarker) => {
      // Dont change activated markes until a new
      // park is activated
      if (activePark) {
        // Set previously activated marker to green dot
        if (parkMarker.activated && activePark.id != parkMarker.park_id) {
          deactivateMarker(parkMarker)
        }

        // Activate marker and set icon to park
        if (!parkMarker.activated && activePark.id == parkMarker.park_id) {
          activateMarker(parkMarker)
        }
      }
    })
  }, [activePark])

  const refreshMarkers = (map: L.Map) => {
    markers.forEach((parkMarker) => {
      map.removeLayer(parkMarker.marker)
    })

    const newMarkers = []
    const markersForZoom = []
    parks.forEach((park) => {
      if (park.latitude && park.longitude) {
        let coords = new L.LatLng(parseFloat(park.latitude), parseFloat(park.longitude))

        let marker = L.marker(coords, {
          icon: mapIcons.circleIcon('fill-green-400'),
        }).addTo(map)

        marker.bindPopup(parkPopup(park), { className: 'wide-popup' })

        marker.on('mouseover', (e) => {
          markers.forEach((parkMarker) => {
            // Set previously activated marker to green dot
            if (parkMarker.activated) {
              deactivateMarker(parkMarker)
            }
          })

          e.target.openPopup()
          e.target.setIcon(mapIcons.parkIcon())
          setActivePark(park)
        })

        newMarkers.push({ park_id: park.id, activated: false, marker: marker })
        markersForZoom.push(marker)
      }
    })

    setMarkers(newMarkers)

    // Set map zoom to bounds of markers
    const bounds = L.featureGroup(markersForZoom).getBounds()
    if (bounds.isValid()) {
      map.fitBounds(bounds, {
        padding: [10, 10],
        maxZoom: 12,
      })
    }
  }

  const activateMarker = (marker: ParkMarker) => {
    marker.activated = true
    marker.marker.setIcon(mapIcons.parkIcon())
    marker.marker.openPopup()
  }

  const deactivateMarker = (marker: ParkMarker) => {
    marker.activated = false
    marker.marker.setIcon(mapIcons.circleIcon('fill-green-400'))
    marker.marker.closePopup()
  }

  const parkPopup = (park: MappablePark) => {
    return `<div class="card border-0 park-popup">
              <div class="row no-gutters">
                ${
                  park.display_image
                    ? `<div class="col-auto">
                        <div class="park-image bg-center-cover"
                          style="background-image: url(${park.display_image.image_url});">
                        </div>
                      </div>`
                    : ''
                }
                <div class="col d-flex align-items-center">
                  <p class="overline text-uppercase text-gray-900 ${
                    park.display_image ? 'mx-3' : 'm-3'
                  }">${park.name}</p>
                </div>
              </div>
              <div class="row no-gutters rounded-bottom bg-gray-800">
                <div class="col m-2">
                  <a class="link-light h-25 ml-2" href="/campgrounds/parks/${park.id}">
                    View
                  </a>
                </div>
              </div>
            </div>`
  }

  return (
    <div className="ke-map interactive-map park-map">
      <div id={mapId} className="h-100"></div>
    </div>
  )
}

export default ParkMap
