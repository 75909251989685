import { EditReservationContext } from '@components/camp/reservations/EditReservation'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Badge } from 'react-bootstrap'
import { formatCurrency } from '@utils/strings'
import FeatherIcon from '@components/shared/FeatherIcon'

interface ConsumableCartItemProps {
  item: any
}

const ConsumableCartItem: React.FC<ConsumableCartItemProps> = observer(({ item }) => {
  const store = useContext(EditReservationContext)

  return (
    <Badge className="bg-gray-100 w-100 mt-2 p-0">
      <div className="px-2 py-1 d-flex justify-content-between align-items-center">
        <div>
          <button
            type="button"
            className="btn link-primary pl-0 pr-2"
            onClick={() => store.removeFromCart(item.uuid)}
            aria-label="Remove Item">
            <div className="d-flex align-items-center">
              <FeatherIcon name="x-circle" />
            </div>
          </button>
          <span className="font-weight-medium">
            {item.name} x {item.quantity}
          </span>
        </div>
        <p className="h5 mb-0">${formatCurrency(item.subtotal_with_quantity)}</p>
      </div>
    </Badge>
  )
})

export default ConsumableCartItem
