import { calculateSiteSubtotal } from '@components/camp/shared/calculateSiteSubtotal'
import AddSiteCard from '@components/camp/reservations/shared/AddSiteCard'
import React, { createContext } from 'react'
import useFetch from 'use-http'
import { useObserver, useLocalStore } from 'mobx-react-lite'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { startOfDay, parseISO } from 'date-fns'
import { formatCurrency, formatDollars } from '@utils/strings'
import useDidMount from '@hooks/useDidMount'
import BookingCards from './BookingCards'
import CartSummary from './CartSummary'

interface EditReservationProps {
  reservation: any
  history: any
  cart: any
  cartUrl: string
  mapUrl: string
  reservationUrl: string
  customer: any
  esSites: any
}

export const EditReservationContext = createContext(null)

const EditReservation: React.FC<EditReservationProps> = ({
  reservation,
  history,
  cart,
  cartUrl,
  mapUrl,
  reservationUrl,
  customer,
  esSites,
}) => {
  const { post, response } = useFetch(cartUrl, {
    headers: (window as any).__STOREFRONT__.formToken,
    cachePolicy: 'no-cache',
  } as any)

  const store = useLocalStore(() => ({
    reservationUrl,
    reservation,
    history,
    cart,
    esSites,
    get errorsForBooking() {
      return (booking_id: number) => {
        const errorHash = (this.reservation.error || []).find((error) => error['id'] == booking_id)
        return errorHash ? errorHash.errors : null
      }
    },
    get bookingItems() {
      return this.cart.cart_items.filter((item) => item.type == 'BookingItem')
    },
    get consumableItems() {
      return this.cart.cart_items.filter((item) => item.type == 'ConsumableItem')
    },
    // Bookings with associated booking items and consumable items
    get aggregatedBookings() {
      return this.reservation.bookings.map((booking) => {
        const bookingItem = this.bookingItems.find(
          (item) => parseInt(item.booking_id) == parseInt(booking.id)
        )

        const consumableItems = this.consumableItems.filter(
          (item) => parseInt(item.booking_id) == parseInt(booking.id)
        )

        return { ...booking, bookingItem, consumableItems }
      })
    },
    get bookingSubtotal() {
      return (booking_id: number) => {
        const booking = this.reservation.bookings.find((booking) => booking.id == booking_id)
        const checkIn = startOfDay(parseISO(booking.start_at))
        const checkOut = startOfDay(parseISO(booking.end_at))
        const esSite = this.esSites.find((site) => site.id == booking.zone_id)

        return calculateSiteSubtotal(esSite, checkIn, checkOut, reservation.park.park_seasons)
      }
    },
    get siteTotal() {
      let total = this.reservation.bookings.reduce((acc, booking) => {
        return acc + parseFloat(this.bookingSubtotal(booking.id))
      }, 0)
      return formatCurrency(total)
    },
    get consumableTotal() {
      let total = this.consumableItems.reduce((acc, consumableItem) => {
        return acc + parseFloat(consumableItem.subtotal_with_quantity)
      }, 0)
      return formatCurrency(total)
    },
    get campgroundFees() {
      // booking fee for first booking in the cart. Fees only charged once, not per booking.
      const bookingItem = this.cart.cart_items.find((item) => item.type == 'BookingItem')
      let fee = bookingItem ? parseFloat(bookingItem.surcharge) : 0
      return formatCurrency(fee)
    },
    get taxes() {
      let taxes = this.reservation.bookings.reduce((acc, booking) => {
        return (
          acc + parseFloat(this.bookingSubtotal(booking.id)) * parseFloat(booking.tax_multiplier)
        )
      }, 0)

      // Apply the tax multiplier from the first booking in the reservation
      // to the reservation fee for MVP
      const firstBookingMultiplier = this.reservation.bookings[0].tax_multiplier || 0
      taxes += parseFloat(this.campgroundFees) * firstBookingMultiplier

      return formatCurrency(taxes)
    },
    get reservationTotal() {
      return formatCurrency(
        parseFloat(this.siteTotal) +
          parseFloat(this.consumableTotal) +
          parseFloat(this.campgroundFees) +
          parseFloat(this.taxes)
      )
    },
    get editedReservationTotal() {
      const editedTotal =
        this.reservationTotal - parseFloat(this.reservation.previous_payment_amount)
      return formatDollars(editedTotal)
    },
    setCart(cart) {
      this.cart = cart
    },
    updateBooking(booking_id: number, attr: string, value: any) {
      const booking = this.reservation.bookings.find((booking) => booking.id == booking_id)
      booking[attr] = value
    },
    updateBookingOccupant(booking_id: number, isCustomer: boolean) {
      const booking = this.reservation.bookings.find((booking) => booking.id == booking_id)
      if (isCustomer) {
        setCustomerOccupant(booking.primary_occupant)
      } else {
        setBlankOccupant(booking.primary_occupant)
      }
    },
    removeFromCart: async function(uuid) {
      const item = { uuid, format: 'json' }
      const res = await post('/remove_from_cart', item)
      if (response.ok) {
        store.setCart(res)
      }
    },
  }))

  // Set primary occupant to current customer on applicable bookings
  useDidMount(() => {
    store.reservation.bookings.forEach((booking) => {
      if (!booking.primary_occupant) {
        // initialize blank primary occupant object
        booking.primary_occupant = {}
        setBlankOccupant(booking.primary_occupant)
      }
      if (booking.primary_occupant && booking.primary_occupant.customer_id) {
        setCustomerOccupant(booking.primary_occupant)
      }
    })
  })

  const setCustomerOccupant = (occupant) => {
    occupant.customer_id = customer.id
    occupant.first_name = customer.first_name
    occupant.last_name = customer.last_name
    occupant.email = customer.email_address
    occupant.phone_number = customer.work_phone
  }

  const setBlankOccupant = (occupant) => {
    occupant.customer_id = null
    occupant.first_name = null
    occupant.last_name = null
    occupant.email = null
    occupant.phone_number = null
  }

  return useObserver(() => (
    <EditReservationContext.Provider value={store}>
      <Container className="py-3">
        <Row>
          <Col xs={8}>
            <input type="hidden" name="camp_reservation[id]" value={store.reservation.id} />
            {store.reservation.error ? (
              <Alert variant={'danger'}>
                Unable to update your reservation, please check the errors below:
              </Alert>
            ) : null}
            <BookingCards />
          </Col>
          <Col>
            <CartSummary />
            {!store.reservation.is_draft ? <AddSiteCard mapUrl={mapUrl} /> : null}
          </Col>
        </Row>
      </Container>
    </EditReservationContext.Provider>
  ))
}

export default EditReservation
