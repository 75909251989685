import { CartContext } from '@components/camp/reservations/Cart'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Container, Row, Col, Card, Badge } from 'react-bootstrap'
import FeatherIcon from '@components/shared/FeatherIcon'
import { formatCurrency } from '@utils/strings'

const ConsumableCards: React.FC = observer(() => {
  const store = useContext(CartContext)

  return (
    <Row>
      {store.consumables.map((consumable) => {
        return (
          <Col xs={6} key={`consumable-card-${consumable.id}`}>
            <Card className="mb-3 border-0">
              <Card.Body className="p-2">
                <Container className="px-1">
                  <Row className="border-bottom pb-2">
                    <Col xs="auto">
                      <div
                        className="rounded bg-center-cover"
                        style={{
                          backgroundImage: `url(${consumable.thumbnail_url})`,
                          width: '80px',
                          height: '80px',
                        }}></div>
                    </Col>
                    <Col>
                      <div>
                        <p className="h5 mb-0">{consumable.name}</p>
                        <p className="font-size-sm mb-0 text-gray-500">{consumable.display_name}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="d-flex justify-content-between align-items-center">
                      <Badge className="bg-gray-100">
                        <div className="px-2">
                          <p className="h5 mb-0">${formatCurrency(consumable.price)}</p>
                        </div>
                      </Badge>
                      <button
                        className="btn link-primary"
                        onClick={() => store.setSelectedConsumable(consumable)}>
                        <div className="d-flex align-items-center">
                          Add
                          <FeatherIcon name="plus-circle" className={`ml-2`} />
                        </div>
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        )
      })}
    </Row>
  )
})

export default ConsumableCards
