import { EventActivity, EventsProgram, Instructor } from '@interfaces/events'
import * as EventFormUtils from './EventFormUtils'

interface EventFormValidationData {
  eventActivities: EventActivity[]
  eventName: string
  capacity: number
  aboutThisEvent: string
  registrationConfirmationMessage: string
  waitlist: number
  minAge: string
  minAgeDateCalculation: string
  maxAge: string
  maxAgeDateCalculation: string
  instructors: Instructor[]
}

const hasValidText = (text: string): boolean => text.trim().length > 0
const hasEventActivities = (eventActivities: EventActivity[]): boolean => eventActivities.length > 0
const hasValidWaitlist = (waitlist: number, capacity: number, program: EventsProgram): boolean =>
  waitlist >= 0 && waitlist <= EventFormUtils.calculateMaxCapacity(program, capacity)
const hasValidInstructors = (instructors: Instructor[]): boolean => instructors.length > 0
// Must have a date calculation if an age is specified
const hasCalculationForAge = (ageRestriction: string, dateCalculation: string): boolean =>
  !ageRestriction || (ageRestriction && hasValidText(dateCalculation))

export const isValid = (
  {
    eventActivities,
    eventName,
    capacity,
    aboutThisEvent,
    registrationConfirmationMessage,
    waitlist,
    minAge,
    minAgeDateCalculation,
    maxAge,
    maxAgeDateCalculation,
    instructors,
  }: EventFormValidationData,
  program: EventsProgram
): boolean =>
  hasEventActivities(eventActivities) &&
  hasValidText(eventName) &&
  capacity > 0 &&
  hasValidText(aboutThisEvent) &&
  hasValidText(registrationConfirmationMessage) &&
  hasValidWaitlist(waitlist, capacity, program) &&
  hasValidInstructors(instructors) &&
  hasCalculationForAge(minAge, minAgeDateCalculation) &&
  hasCalculationForAge(maxAge, maxAgeDateCalculation)
