import { CartReviewContext } from '@components/camp/reservations/Cart/Review'
import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Row, Col, Card, Button, Alert } from 'react-bootstrap'
import { parse, format } from 'date-fns'
import classNames from 'classnames'
import NewPaymentForm from './NewPaymentForm'

const PaymentInformation: React.FC = observer(() => {
  const store = useContext(CartReviewContext)

  return (
    <>
      <Row>
        <Col className="mt-3">
          <h2 className="h4 border-bottom mt-2 mb-4">Payment Information</h2>
          <Card>
            <Card.Body>
              {store.paymentErrors ? (
                <Row>
                  <Col>
                    <Alert key={`payment-errors`} variant={'danger'}>
                      <ul className="mb-0 pl-3">
                        {store.paymentErrors.map((error, idx) => (
                          <li className="font-size-sm" key={`error-${idx}`}>
                            {error}
                          </li>
                        ))}
                      </ul>
                    </Alert>
                  </Col>
                </Row>
              ) : null}
              {/* dont show payment info when processing refund */}
              {store.reservation.is_draft && parseFloat(store.editedReservationTotal) <= 0 ? (
                <Row>
                  <Col xs={10}>
                    <p>
                      The refund amount will be placed back on the original payment method you used
                      to make the reservation:
                    </p>
                    <p>
                      {store.history.previous_cart.payment_type}:{' '}
                      {store.history.previous_cart.payment_detail}
                    </p>
                  </Col>
                </Row>
              ) : (
                <React.Fragment>
                  <Row>
                    <Col xs={7}>
                      <p>This card will be used to complete the purchase:</p>
                    </Col>
                  </Row>
                  {store.paymentMethods.length ? (
                    <Row>
                      <Col xs={7}>
                        <Form.Group controlId="payment-method">
                          <Form.Control
                            as="select"
                            value={store.paymentMethod.id}
                            disabled={store.useNewCard}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                              const method = store.paymentMethods.find(
                                (pm) => pm.id == e.target.value
                              )
                              store.setPaymentMethod(method)
                            }}>
                            {store.paymentMethods.map((paymentMethod, idx) => (
                              <option value={paymentMethod.id} key={idx}>
                                {`**** **** **** ${paymentMethod.card_number.substr(12)}`}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs={5}>
                        <p
                          className={classNames('mb-0 font-size-sm font-weight-medium', {
                            'text-gray-400': store.useNewCard,
                          })}>
                          {store.paymentMethod.first_name} {store.paymentMethod.last_name}
                        </p>
                        <p
                          className={classNames('font-size-sm font-weight-medium', {
                            'text-gray-400': store.useNewCard,
                          })}>
                          Expires {store.paymentMethod.expiration}
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                  {!store.useNewCard ? (
                    <Row>
                      <Col>
                        <Button variant="outline-primary" onClick={() => (store.useNewCard = true)}>
                          Add New Payment Method
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  {store.useNewCard ? <NewPaymentForm /> : null}
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4 pt-4 border-top">
        <Col xs="auto">
          <Button
            variant="primary"
            onClick={() => store.processCheckout()}
            disabled={!store.paymentMethod || store.useNewCard}>
            {store.reservation.is_draft && store.editedReservationTotal < 0
              ? 'Complete Refund'
              : 'Complete Payment'}
          </Button>
        </Col>
        <Col xs="auto">
          <a href={store.reservationUrl} data-method="delete" className="btn link-primary">
            {store.reservation.is_draft ? 'Cancel Edit Reservation' : 'Cancel Reservation'}
          </a>
        </Col>
      </Row>
    </>
  )
})

export default PaymentInformation
