import React from 'react'

interface NpmapIconProps {
  // Name of the icon from the NPMap SVG sprite
  name: string
  className?: string
  alt?: string
}

const NpmapIcon: React.FC<NpmapIconProps> = ({ name, className = '', alt = '', ...otherProps }) => {
  const npmapAssetPath = (window as any).__STOREFRONT__.npmapSvgPath
  const altPresent = alt && alt.length !== 0

  if (!altPresent) {
    otherProps['aria-hidden'] = 'true'
  }

  return name ? (
    <svg
      viewBox="0 0 30 30"
      className={`npmap-icon ${className}`}
      focusable="false"
      role="img"
      {...otherProps}>
      {altPresent ? <title>{alt}</title> : null}
      <use href={`${npmapAssetPath}#icon-${name}`} aria-hidden="true"></use>
    </svg>
  ) : null
}

export default NpmapIcon
