import { EditReservationContext } from '@components/camp/reservations/EditReservation'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Card } from 'react-bootstrap'
import { parseISO, compareDesc, differenceInCalendarDays } from 'date-fns'
import { countDisplay } from '@utils/strings'
import classNames from 'classnames'
import ConsumableCartItem from './ConsumableCartItem'

interface BookingCartItemProps {
  booking: any
  subtotal: number
  firstBooking: boolean
}

const BookingCartItem: React.FC<BookingCartItemProps> = observer(
  ({ booking, subtotal, firstBooking }) => {
    const store = useContext(EditReservationContext)

    const checkIn = parseISO(booking.start_at)
    const checkOut = parseISO(booking.end_at)
    const night_count =
      compareDesc(checkIn, checkOut) > 0 ? differenceInCalendarDays(checkOut, checkIn) : 0

    return (
      <React.Fragment>
        <Card
          className={classNames({ 'mt-3': !firstBooking })}
          key={`booking-summary-${booking.id}`}>
          <Card.Header className="d-flex justify-content-between align-items-center p-2">
            <div>
              <p className="h5 mb-0">
                {booking.site_name} &bull; {booking.loop_name}
              </p>
              <p className="font-size-sm mb-0">{booking.section_name}</p>
            </div>
            <div>
              <p className="h5 mb-0">${subtotal}</p>
            </div>
          </Card.Header>
          <Card.Body className="p-2">
            <p className="mb-0">
              {countDisplay('Guest', booking.occupant_count)},&nbsp;
              {countDisplay('Vehicle', booking.vehicle_count)},&nbsp;
              {countDisplay('Night', night_count)}
            </p>
          </Card.Body>
        </Card>
        {booking.consumableItems.map((item, idx) => (
          <ConsumableCartItem item={item} key={`consumable-${idx}`} />
        ))}
      </React.Fragment>
    )
  }
)

export default BookingCartItem
