import React from 'react'
import NpmapIcon from '@components/shared/NpmapIcon'
import { Badge } from 'react-bootstrap'

interface SiteBadgeProps {
  siteTypeName: string
  iconName: string
  textClass?: string
}

const SiteBadge: React.FC<SiteBadgeProps> = ({ siteTypeName, iconName, textClass }) => {
  return (
    <Badge className={`npmap-colored-${iconName}`}>
      <div className="px-1 d-flex align-items-center">
        <span className={textClass}>{siteTypeName}</span>
        <NpmapIcon name={iconName} className={`ml-2`} />
      </div>
    </Badge>
  )
}

SiteBadge.defaultProps = { textClass: 'text-base', iconName: 'campsite' }

export default SiteBadge
