import React from 'react'
import L from 'leaflet'
import * as mapLayers from '@components/map/mapLayers'
import * as mapIcons from '@components/map/mapIcons'
import useDidMount from '@hooks/useDidMount'

const defaultZoom = 16
const mapId = `leaflet-map-${Date.now()}`

interface StaticSiteMapProps {
  site: any
}

const StaticSiteMap: React.FC<StaticSiteMapProps> = ({ site }) => {
  // Set activated = true icon for site
  const popupIcon = mapIcons.popupIconForSite(true, site.site_type.icon_name)
  let coordinates = new L.LatLng(parseFloat(site.latitude), parseFloat(site.longitude))

  // Initialize Leaflet Map
  useDidMount(() => {
    const map = L.map(mapId, {
      attributionControl: false,
      zoomControl: false,
    }).setView(coordinates, defaultZoom)

    mapLayers.setupLayersAndControls(map)

    L.marker(coordinates, {
      icon: popupIcon,
    }).addTo(map)
  })

  return (
    <div className="ke-map">
      <div id={mapId} style={{ height: '300px' }}></div>
    </div>
  )
}

export default StaticSiteMap
