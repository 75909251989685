import { CartContext } from '@components/camp/reservations/Cart'
import React, { useState, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Container, Form, Row, Col, Button, Badge, Modal } from 'react-bootstrap'
import { parseISO, format } from 'date-fns'
import classNames from 'classnames'
import { formatCurrency } from '@utils/strings'
import FeatherIcon from '@components/shared/FeatherIcon'

const ConsumableModal: React.FC = observer(() => {
  const store = useContext(CartContext)
  const firstBookingId = store.reservation.bookings[0].id
  const consumable = store.selectedConsumable
  const [quantity, setQuantity] = useState(1)
  const [bookingId, setBookingId] = useState(firstBookingId)

  const decrementDisabled = quantity <= 1
  const incrementDisabled =
    consumable && consumable.max_quantity_per_transaction
      ? quantity >= consumable.max_quantity_per_transaction
      : false

  const clearModal = () => {
    store.setSelectedConsumable(null)
    setQuantity(1)
    setBookingId(firstBookingId)
  }

  const addToCart = () => {
    store.addToCart(consumable, quantity, parseInt(bookingId))
    clearModal()
  }

  return !!consumable ? (
    <Modal show centered backdrop="static" dialogClassName="modal-md-lg">
      <Modal.Body className="p-4">
        <Container>
          <Row>
            <Col xs="auto">
              <div
                className="rounded bg-center-cover d-flex align-items-end"
                style={{
                  backgroundImage: `url(${store.selectedConsumable.thumbnail_url})`,
                  width: '160px',
                  height: '160px',
                }}>
                <div className="w-100 text-center bg-gray-800">
                  <p className="h5 mb-0 text-white my-1">${formatCurrency(consumable.price)} ea</p>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <p className="h3 mb-3">{consumable.name}</p>
                <p className="mb-0 text-gray-900">{consumable.display_name}</p>
              </div>
            </Col>
          </Row>
          <Row className="bg-gray-100 p-3 my-3">
            <Col xs="auto">
              <Button
                variant="outline-primary"
                className="p-1"
                disabled={decrementDisabled}
                onClick={() => setQuantity(quantity - 1)}
                aria-label="Decrease Quantity">
                <FeatherIcon
                  name="minus"
                  aria-hidden="true"
                  className={classNames('icon-xl', { 'stroke-gray-500': decrementDisabled })}
                />
              </Button>
              <Badge className="bg-white mx-3">
                <div className="px-2">
                  <p className="h5 mb-0 p-1">{quantity}</p>
                </div>
              </Badge>
              <Button
                variant="outline-primary"
                className="p-1"
                disabled={incrementDisabled}
                onClick={() => setQuantity(quantity + 1)}
                aria-label="Increase Quantity">
                <FeatherIcon
                  name="plus"
                  aria-hidden="true"
                  className={classNames('icon-xl', { 'stroke-gray-500': incrementDisabled })}
                />
              </Button>
            </Col>
            <Col>
              <Form.Group controlId="booking-addon">
                <Form.Control
                  as="select"
                  value={bookingId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setBookingId(e.target.value)
                  }>
                  {store.reservation.bookings.map((booking, idx) => {
                    const startAt = format(parseISO(booking.start_at), 'MM/dd')
                    const endAt = format(parseISO(booking.end_at), 'MM/dd')
                    return (
                      <option value={booking.id} key={idx}>
                        {`Site ${booking.site_name} - ${booking.primary_occupant.last_name} (${startAt} - ${endAt})`}
                      </option>
                    )
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Button variant="outline-primary" className="mr-2 btn-wide" onClick={clearModal}>
              Cancel
            </Button>
            <Button variant="primary" className="btn-wide" onClick={addToCart}>
              Add to Cart
            </Button>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  ) : null
})

export default ConsumableModal
