import { SearchSitesContext } from '@components/camp/sites/SearchSites'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import SiteCard from './SiteCard'

const SiteMapSidebar: React.FC = observer(() => {
  const store = useContext(SearchSitesContext)

  return store.filteredMergedSites.map((site, site_idx) => (
    <SiteCard site={site} site_idx={site_idx} key={`site-${site.id}`} />
  ))
})

export default SiteMapSidebar
