import { CartReviewContext } from '@components/camp/reservations/Cart/Review'
import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, Col, Card, Badge } from 'react-bootstrap'
import { parseISO, format, differenceInCalendarDays } from 'date-fns'
import { countDisplay, formatCurrency } from '@utils/strings'
import FeatherIcon from '@components/shared/FeatherIcon'
import SiteBadge from '@components/camp/sites/SiteBadge'

const BookingCards: React.FC = observer(() => {
  const store = useContext(CartReviewContext)

  return (
    <>
      <h2 className="h4 border-bottom mt-2 mb-4">Bookings</h2>
      {store.aggregatedBookings.map((booking, idx) => {
        const checkIn = parseISO(booking.start_at)
        const checkOut = parseISO(booking.end_at)
        const night_count = differenceInCalendarDays(checkOut, checkIn)

        return (
          <Card className="mb-3 border-0" key={`booking-card-${booking.id}`}>
            <Card.Body>
              <Row>
                <Col className="border-bottom d-flex align-items-center justify-content-between pb-3">
                  <div>
                    <p className="h5 mb-0">
                      {booking.site_name} &bull; {booking.loop_name}
                    </p>
                    <p className="font-size-sm mb-0">{booking.section_name}</p>
                  </div>
                  <SiteBadge
                    iconName={booking.site_type_icon}
                    siteTypeName={booking.site_type_name}
                    textClass="font-size-sm"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <p className="overline text-uppercase text-gray-500 mb-1">Dates</p>
                  <p className="mb-2">
                    {format(checkIn, 'MMM dd')} - {format(checkOut, 'MMM dd')}
                  </p>
                  <p className="overline text-uppercase text-gray-500 mb-1">Details</p>
                  <p className="mb-2">
                    {countDisplay('Guest', booking.occupant_count)},&nbsp;
                    {countDisplay('Vehicle', booking.vehicle_count)},&nbsp;
                    {countDisplay('Night', night_count)}
                  </p>
                </Col>
                <Col>
                  <p className="overline text-uppercase text-gray-500 mb-1">Primary Occupant</p>
                  <p className="mb-1">
                    {booking.primary_occupant.first_name} {booking.primary_occupant.last_name}
                  </p>
                  <p className="mb-2">{booking.primary_occupant.email}</p>
                  <p className="mb-1">{booking.primary_occupant.phone_number}</p>
                </Col>
              </Row>
              <Row className="border-top">
                <Col xs={12} className="mt-3">
                  <p className="overline text-uppercase text-gray-500 mb-1">Add-Ons</p>
                </Col>
                {booking.consumableItems.map((item, idx) => (
                  <Col xs={6} key={`consumable-${booking.id}-${idx}`}>
                    <Badge className="bg-gray-100 w-100 mt-2">
                      <div className="px-2 py-1 d-flex">
                        <div
                          className="rounded bg-center-cover"
                          style={{
                            backgroundImage: `url(${store.consumableImage(
                              item.consumable_product_id
                            )})`,
                            width: '50px',
                            height: '50px',
                          }}></div>
                        <div className="ml-3">
                          <p className="h5 mb-0 text-left">{item.name}</p>
                          {/* item subtotal is the same as consumable price */}
                          <p className="font-size-sm font-weight-medium mb-0 text-gray-500 text-left">
                            ({item.quantity} x ${formatCurrency(item.subtotal)})
                          </p>
                        </div>
                        <button
                          className="btn link-primary ml-auto"
                          onClick={() => store.removeFromCart(item.uuid)}
                          aria-label="Remove Item">
                          <div className="d-flex align-items-center">
                            <FeatherIcon name="x-circle" />
                          </div>
                        </button>
                      </div>
                    </Badge>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        )
      })}
    </>
  )
})

export default BookingCards
