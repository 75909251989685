import AddSiteCard from '@components/camp/reservations/shared/AddSiteCard'
import React, { createContext } from 'react'
import useFetch from 'use-http'
import { useObserver, useLocalStore } from 'mobx-react-lite'
import { Container, Row, Col } from 'react-bootstrap'
import { formatCurrency, formatDollars } from '@utils/strings'
import ConsumableCards from './ConsumableCards'
import ConsumableModal from './ConsumableModal'
import CartSummary from './CartSummary'

interface CartProps {
  reservation: any
  history: any
  cart: any
  consumables: any
  reservationUrl: string
  cartUrl: string
  mapUrl: string
  cartReviewUrl: string
}

export const CartContext = createContext(null)

const Cart: React.FC<CartProps> = ({
  reservation,
  history,
  cart,
  consumables,
  reservationUrl,
  cartUrl,
  mapUrl,
  cartReviewUrl,
}) => {
  const { post, loading, response } = useFetch(cartUrl, {
    headers: (window as any).__STOREFRONT__.formToken,
    cachePolicy: 'no-cache',
  } as any)

  const store = useLocalStore(() => ({
    reservation,
    history,
    cart,
    consumables,
    reservationUrl,
    cartReviewUrl,
    selectedConsumable: null,
    get bookingItems() {
      return this.cart.cart_items.filter((item) => item.type == 'BookingItem')
    },
    get consumableItems() {
      return this.cart.cart_items.filter((item) => item.type == 'ConsumableItem')
    },
    // Bookings with associated booking items and consumable items
    get aggregatedBookings() {
      return this.reservation.bookings.map((booking) => {
        const bookingItem = this.bookingItems.find(
          (item) => parseInt(item.booking_id) == parseInt(booking.id)
        )

        const consumableItems = this.consumableItems.filter(
          (item) => parseInt(item.booking_id) == parseInt(booking.id)
        )

        return { ...booking, bookingItem, consumableItems }
      })
    },
    get siteTotal() {
      let total = this.bookingItems.reduce((acc, bookingItem) => {
        return acc + parseFloat(bookingItem.subtotal)
      }, 0)
      return formatCurrency(total)
    },
    get consumableTotal() {
      let total = this.consumableItems.reduce((acc, consumableItem) => {
        return acc + parseFloat(consumableItem.subtotal_with_quantity)
      }, 0)
      return formatCurrency(total)
    },
    get campgroundFees() {
      // booking fee for first booking in the cart. Fees only charged once, not per booking.
      const bookingItem = this.bookingItems[0]
      let fee = bookingItem ? parseFloat(bookingItem.surcharge) : 0
      return formatCurrency(fee)
    },
    get taxes() {
      let taxes = this.cart.cart_items.reduce((acc, item) => {
        return acc + parseFloat(item.tax || 0)
      }, 0)

      return formatCurrency(taxes)
    },
    get reservationTotal() {
      return formatCurrency(
        parseFloat(this.siteTotal) +
          parseFloat(this.consumableTotal) +
          parseFloat(this.campgroundFees) +
          parseFloat(this.taxes)
      )
    },
    get editedReservationTotal() {
      const editedTotal =
        this.reservationTotal - parseFloat(this.reservation.previous_payment_amount)
      return formatDollars(editedTotal)
    },
    setSelectedConsumable(consumable) {
      this.selectedConsumable = consumable
    },
    setCart(cart) {
      this.cart = cart
    },
    addToCart: async function (consumable, quantity, bookingId) {
      // TODO: We should assign the item price in AMS so that
      // it can't be changed by the customer
      const item = {
        type: 'ConsumableItem',
        name: consumable.name,
        quantity: quantity,
        consumable_product_id: consumable.id,
        product_variation_id: consumable.product_variations[0].id,
        booking_id: bookingId,
        subtotal: consumable.price,
        format: 'json',
      }

      const res = await post('/add_to_cart', item)
      if (response.ok) {
        store.setCart(res)
      }
    },
    removeFromCart: async function (uuid) {
      const item = { uuid, format: 'json' }
      const res = await post('/remove_from_cart', item)
      if (response.ok) {
        store.setCart(res)
      }
    },
  }))

  return useObserver(() => (
    <CartContext.Provider value={store}>
      <Container className="py-3">
        <Row>
          <Col xs={8}>
            <ConsumableCards />
          </Col>
          <Col>
            <CartSummary />
            {!store.reservation.is_draft ? <AddSiteCard mapUrl={mapUrl} /> : null}
          </Col>
        </Row>
      </Container>
      <ConsumableModal />
    </CartContext.Provider>
  ))
}

export default Cart
