import L from 'leaflet'

const { I18n } = window

export const initFallbackLayers = (): L.LayerGroup => {
  return L.layerGroup([
    // Kalkomey Maps
    L.tileLayer('https://maps.kalkomey.com/hot/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }),
    // OpenStreetMap Latest
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
    }),
  ])
}

// This shoud be the MapBox tiles but those require
// an access token - not sure about this map as it lacks
// details but it's close to the design so keeping for now
export const initBasicLayers = (): L.LayerGroup => {
  return L.layerGroup([
    // Esri World Light Gray Base
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 16,
        attribution: `Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ`,
      }
    ),
    // Esri World Light Gray Reference
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 16,
      }
    ),
  ])
}

export const initStreetLayers = (): L.LayerGroup => {
  return L.layerGroup([
    // Stamen Terrain Base
    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png', {
      maxZoom: 17,
      attribution: I18n.t('views.ke_map.attribution_html'),
    }),
    // Stamen Watercolor
    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg', {
      maxZoom: 17,
      minZoom: 1,
    }),
    // Stamen Terrain Overlay
    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png', {
      maxZoom: 17,
      opacity: 0.45,
      className: 'mix-multiply',
    }),
    // Stamen Terrain Labels
    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-labels/{z}/{x}/{y}.png', {
      maxZoom: 17,
    }),
  ])
}

export const initSatelliteLayers = (): L.LayerGroup => {
  return L.layerGroup([
    // Esri World Imagery
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 19,
        attribution: `Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping,
        Aerogrid, IGN, IGP, UPR-EGP, GIS Editors`,
      }
    ),
    // Esri World Boundaries And Places
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_And_Places/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 19,
      }
    ),
  ])
}

export const initHybridLayers = (): L.LayerGroup => {
  return L.layerGroup([
    // Esri World Imagery
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 19,
        attribution: `Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping,
        Aerogrid, IGN, IGP, UPR-EGP, GIS Editors`,
      }
    ),
    // Esri World Boundaries And Places
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_And_Places/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 19,
      }
    ),
    // Esri World Transportation
    L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
      {
        maxZoom: 19,
      }
    ),
  ])
}

export const setupLayersAndControls = (map: L.Map): void => {
  const layers = {
    fallback: initFallbackLayers(),
    basic: initBasicLayers(),
    street: initStreetLayers(),
    satellite: initSatelliteLayers(),
    hybrid: initHybridLayers(),
  }

  // In case one of the "main" layer groups goes offline, we fallback to these
  // Getting 404s from fallback layers - removing for now
  // layers.fallback.addTo(map)

  // Default Layer Group
  layers.basic.addTo(map)
  // Setup selectable Layer Groups
  L.control
    .layers({
      Basic: layers.basic,
      Satellite: layers.satellite,
      Street: layers.street,
      Hybrid: layers.hybrid,
    })
    .addTo(map)

  // after layers so controls stack accordingly
  L.control.zoom({ position: 'topright' }).addTo(map)
  L.control.attribution({ prefix: false }).addTo(map)
}
