// Calculates the subtotal for a site given:
//
// 1. An elasticsearch site with internet pricing
// 2. Check in date
// 3. Check-out date (function will subtract a day for pricing)
// 4. An array of park peak season dates
//
// returns 0 if check-in is equal to or after check-out

import {
  compareDesc,
  eachDayOfInterval,
  subDays,
  isWeekend,
  isWithinInterval,
  parseISO,
} from 'date-fns'

export const calculateSiteSubtotal = (
  esSite: any,
  checkIn: Date,
  checkOut: Date,
  parkSeasons: any
) => {
  // Do not charge for check out day
  const lastChargedDay = subDays(checkOut, 1)

  // return 0 unless there is at least one day to charge
  if (!(compareDesc(checkIn, lastChargedDay) >= 0)) {
    return 0.0
  }

  // Use internet pricing records
  const {
    peak_weekday,
    peak_weekend,
    offpeak_weekday,
    offpeak_weekend,
  } = esSite.pricing_records.internet

  // Sum prices for each day based on peak/non-peak weeday/weekend pricing
  const bookingNights = eachDayOfInterval({ start: checkIn, end: lastChargedDay })
  let subtotal = bookingNights.reduce((acc, bookingDate) => {
    const weekend = isWeekend(bookingDate)
    const peak = parkSeasons.some((season) =>
      isWithinInterval(bookingDate, {
        start: parseISO(season.start_at),
        end: parseISO(season.end_at),
      })
    )

    if (peak && weekend) {
      return acc + parseFloat(peak_weekend)
    } else if (peak && !weekend) {
      return acc + parseFloat(peak_weekday)
    } else if (!peak && weekend) {
      return acc + parseFloat(offpeak_weekend)
    } else if (!peak && !weekend) {
      return acc + parseFloat(offpeak_weekday)
    }
  }, 0)

  return subtotal.toFixed(2)
}
