import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import classNames from 'classnames'
import { MappablePark } from '@interfaces/maps'
import NpmapIcon from '@components/shared/NpmapIcon'
import { onKeyActivate } from '@utils/onKeyActivate'

interface ParkCardProps {
  // Park to display
  park: MappablePark
  park_idx: number
  base_url: string
  activated: boolean
  lastPark: boolean
  setActivePark(park: MappablePark): void
}

const ParkCard: React.FC<ParkCardProps> = ({
  park,
  park_idx,
  base_url,
  activated,
  lastPark,
  setActivePark,
}) => {
  const showHoverEffect = () => {
    setActivePark(park)
  }

  const redirectToPark = () => {
    const park_url = `${base_url}/${park.id}`
    window.location.href = park_url
  }

  return (
    <Row className={classNames('bg-white', { 'pt-2': park_idx == 0 }, { 'pb-2': lastPark })}>
      <Col className="mx-2 my-0">
        <Card
          onMouseEnter={showHoverEffect}
          onClick={redirectToPark}
          onKeyDown={onKeyActivate('click')}
          tabIndex={0}
          className={classNames('cursor-pointer border-0', {
            'shadow-xl': activated,
            'shadow-sm': !activated,
          })}>
          <Card.Body>
            <Row>
              {park.display_image ? (
                <Col xs="auto" className="d-flex align-items-center">
                  <div
                    className="rounded bg-center-cover"
                    style={{
                      backgroundImage: `url(${park.display_image.image_url})`,
                      width: '80px',
                      height: '100px',
                    }}></div>
                </Col>
              ) : null}
              <Col className="d-flex flex-column justify-content-between">
                <div>
                  <p
                    className={classNames('h5 mb-0', {
                      'text-green-800': activated,
                    })}>
                    {park.name}
                  </p>
                  <p className="h5 font-weight-normal">
                    {park.city}, {park.state_code}
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <p className="h5 text-gray-500 mb-0 d-flex align-items-end">
                    {park.site_count}&nbsp;&nbsp;
                    <span className="overline text-uppercase pb-1">
                      {`Site${park.site_count > 1 ? 's' : ''}`}
                    </span>
                  </p>
                  <p className="mb-0">
                    {park.site_type_icons.map((icon, idx) => (
                      <React.Fragment key={`icon-${idx}`}>
                        <NpmapIcon
                          name={icon}
                          className={`npmap-colored-${icon} icon-xl p-1 mr-1`}
                        />
                        {idx > 0 && idx % 3 == 0 ? <br /> : null}
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {!lastPark ? <hr></hr> : null}
      </Col>
    </Row>
  )
}

export default ParkCard
