import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'

interface AddSiteCardProps {
  mapUrl: string
}

const AddSiteCard: React.FC<AddSiteCardProps> = ({ mapUrl }) => {
  return (
    <Card className="mt-3 border-0">
      <Card.Body>
        <Row>
          <Col>
            <p className="text-center mb-1">Need to book another site?</p>
            <p className="text-center font-size-sm text-gray-500">
              You can add multiple sites from this park to this reservation.
            </p>
            <Button variant="secondary" block href={mapUrl}>
              Add Another Site
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default AddSiteCard
