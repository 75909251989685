import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ParkCard from './ParkCard'
import { MappablePark } from '@interfaces/maps'

interface ParkMapSidebarProps {
  // Search query
  parks: Array<MappablePark>
  base_url: string
  activePark: MappablePark
  setActivePark(park: MappablePark): void
}

const ParkMapSidebar: React.FC<ParkMapSidebarProps> = ({
  parks,
  base_url,
  activePark,
  setActivePark,
}) => {
  return (
    <>
      <Row className="bg-gray-100">
        <Col className="ml-4 mt-4">
          <p className="overline text-uppercase mb-0">{parks.length} Parks</p>
          <p>In South Dakota</p>
        </Col>
      </Row>
      {parks.map((park, park_idx) => (
        <ParkCard
          park={park}
          park_idx={park_idx}
          base_url={base_url}
          key={`park-${park.id}`}
          activated={activePark && activePark.id == park.id}
          lastPark={park_idx == parks.length - 1}
          setActivePark={setActivePark}
        />
      ))}
    </>
  )
}

export default ParkMapSidebar
