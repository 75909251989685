import React, { createContext } from 'react'
import { useObserver, useLocalStore } from 'mobx-react-lite'
import { Container, Row, Col } from 'react-bootstrap'
import { parse, format, eachDayOfInterval, subDays, compareDesc } from 'date-fns'
import qs from 'qs'
import SearchForm from './SearchForm'
import SiteMapSidebar from './SiteMapSidebar'
import SiteMap from './SiteMap'

interface SearchSitesProps {
  sitesEndpoint: string
  sites: Array<any>
  es_sites: Array<any>
  sections: Array<any>
  facilities: Array<any>
}

interface SearchFilter {
  check_in?: string
  check_out?: string
  occupants?: string
  vehicles?: string
  section_id?: string
}

export const SearchSitesContext = createContext(null)

const SearchSites: React.FC<SearchSitesProps> = ({
  sitesEndpoint,
  sites,
  es_sites,
  sections,
  facilities,
}) => {
  const store = useLocalStore(() => ({
    sitesEndpoint,
    sites,
    es_sites,
    sections,
    facilities,
    searchFilter: {} as SearchFilter,
    map: null,
    markers: [],
    activeSite: null,
    setSearchFilter(filter) {
      this.searchFilter = filter
    },
    setMap(map) {
      this.map = map
    },
    setMarkers(markers) {
      this.markers = markers
    },
    setActiveSite(site) {
      this.activeSite = site
    },
    get filterDays() {
      // returns an array of formatted dates for filter date range: ['20201109', '20201110']
      let days = []

      if (store.searchFilter.check_in && store.searchFilter.check_out) {
        const startDate = parse(store.searchFilter.check_in, 'MM/dd/yyyy', new Date())
        const endDate = parse(store.searchFilter.check_out, 'MM/dd/yyyy', new Date())

        // Ensure end date is after start date
        // Subtract 1 day from end date because
        // we dont include checkout day
        if (compareDesc(startDate, endDate) > 0) {
          days = eachDayOfInterval({
            start: startDate,
            end: subDays(endDate, 1),
          }).map((date) => format(date, 'yyyyLLdd'))
        }
      }

      return days
    },
    get mergedSites() {
      // returns all sites with combined AR attributes and ES index attributes
      return store.sites.map((site) => {
        const esSite = store.es_sites.find((es_site) => es_site.id == site.id)

        const available = siteAvailable(esSite, store.filterDays)
        const excluded = siteExcludedByFilter(esSite, store.searchFilter, store.filterDays)
        // Values for internet pricing stored in the ES index
        const pricing: Array<number> = Object.values(esSite.pricing_records.internet)

        return {
          ...site,
          section_id: esSite.section_id,
          loop_name: esSite.loop_name,
          min_price: Math.min(...pricing),
          max_price: Math.max(...pricing),
          section_name: esSite.section_name,
          available: available,
          excluded: excluded,
        }
      })
    },
    get filteredMergedSites() {
      const sectionId = store.searchFilter.section_id
      return store.mergedSites.filter((site) => {
        let showSite = !site.excluded && site.available

        // Sites outside the section are filtered from the sidebar but
        // do not effect the display of the site on the map so
        // the section condition cannot go in the main filter
        if (sectionId && sectionId != '' && sectionId != '0') {
          showSite = showSite && site.section_id == store.searchFilter.section_id
        }

        return showSite
      })
    },
    get siteQuery() {
      const params = {
        check_in:
          this.searchFilter.check_in &&
          format(parse(this.searchFilter.check_in, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd'),
        check_out:
          this.searchFilter.check_out &&
          format(parse(this.searchFilter.check_out, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd'),
        occupants: this.searchFilter.occupants,
        vehicles: this.searchFilter.vehicles,
      }
      return qs.stringify(params, { addQueryPrefix: true })
    },
    get siteTypes() {
      const uniqueTypes = []
      store.es_sites.forEach((site) => {
        if (uniqueTypes.indexOf(site.site_type_name) === -1) {
          uniqueTypes.push(site.site_type_name)
        }
      })
      return uniqueTypes
    },
  }))

  // Filter by occupancy, vehicles, and min/max stay
  const siteExcludedByFilter = (site, filter, filterDays) => {
    let excluded = false
    if (
      (filter.occupants && filter.occupants > site.max_occupancy) ||
      (filter.vehicles && filter.vehicles > site.max_vehicles) ||
      (filterDays.length && filterDays.length < site.min_stay) ||
      (filterDays.length && filterDays.length > site.max_stay) ||
      (filter.site_type && filter.site_type != '0' && filter.site_type != site.site_type_name)
    ) {
      excluded = true
    }

    return excluded
  }

  // Check that active booking nights for the site do
  // not include any dates within the filtered range
  const siteAvailable = (site, filterDays) => {
    let available = true
    if (site.active_booking_dates_array && filterDays.length) {
      available = !site.active_booking_dates_array.some((bookingDate) => {
        return filterDays.some((filterDate) => {
          return bookingDate == filterDate
        })
      })
    }

    return available
  }

  return useObserver(() => (
    <SearchSitesContext.Provider value={store}>
      <Container fluid>
        <SearchForm />
        <Row>
          <Col xs={3} className="bg-gray-100 site-map-sidebar">
            <SiteMapSidebar />
          </Col>
          <Col className="px-0">
            <SiteMap />
          </Col>
        </Row>
      </Container>
    </SearchSitesContext.Provider>
  ))
}

export default SearchSites
